import React, { useEffect, useState } from "react";
import { convertTimeToSecs } from "./helpers";

import TimeContext from "./context";

const TimeStore = ({ children }) => {
  const date = new Date();
  const [timeNow, setTimeNow] = useState(
    convertTimeToSecs(`${date.getHours()}:${date.getMinutes()}`)
  );
  useEffect(() => {
    const intervalId = setInterval(() => {
      // get a new Date value, because if we dont and app goes in background, then timer stops running
      const date = new Date();
      const timeLatest = convertTimeToSecs(
        `${date.getHours()}:${date.getMinutes()}`
      );
      setTimeNow(timeLatest);
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  // component will update only when a full minute in the date object has passed
  console.log("TIME UPDATED", timeNow);

  return (
    <TimeContext.Provider value={timeNow}>{children}</TimeContext.Provider>
  );
};

export default TimeStore;
