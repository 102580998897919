import React, { Component } from "react";


//Importing  components
import Dialog, { NoteDialogWithFb as NoteDialog } from "../Dialog";

import PendingList from "./PendingList";
import PendingLandMode from "../PendingLandMode";
import GoogleMapsPage from "../GoogleMaps";

import SwalNotification from "../Swal";

// import HOC
import { withAuthorization } from "../Session";
import { withCurrentPosition } from "../Geolocation";
import { compose } from "underscore";
import withPickupStore from "../PickupStore/withPickupStore";
import Loader from "../Loader";

// HELPERS
import { isWithinRadius } from "./Helpers";

class PendingPage extends Component {
  constructor(props) {
    super(props);
    //Initiating state.
    this.state = {
      //When set to true we show corresponding dialog.
      viewDialog: false,
      driverPhoto: null,
      uploadTask: null,
      uploadTaskProgress: null,
      uploadError: null,
      showAlert: false,
      alertMessage: "",
      alertStatus: "",
      alertError: "",
      showGmapsLandMode: false,
      GmapsLandModeLat: null,
      GmapsLandModeLng: null,
      showSpinner: false,
    };

    //Dialog as default is set to null.
    this.dialog = null;
    // this.driverForm = null;
  }

  // LIFECYCLE METHODS //
  componentDidMount() {
    this.pendingSection.style.height = `calc(${this.props.windowHeight}px - 9rem)`;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // always change the height of map, if window height changes
    if (prevProps.windowHeight !== this.props.windowHeight) {
      this.pendingSection.style.height = `calc(${this.props.windowHeight}px - 9rem)`;
    }

    if (this.state.uploadTask !== prevState.uploadTask) {
      // if we set an upload task, we also set a listener
      // then on componentwillunmount we unsbuscribe from it
      // if we are already subscribed to a task when asking to subscribe to another, first unsbscibe from the first one
      if (this.unsubscribeUploadTask) {
        this.unsubscribeUploadTask();
      }
      this.unsubscribeUploadTask = this.state.uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          // showing swal with upload percentage
          this.setState({
            uploadTaskProgress: Math.round(progress),
          });
        },
        (error) => {
          // Handle unsuccessful uploads
        },
        // success
        () => {
          this.setState({
            uploadTaskProgress: null,
          });
        }
      );
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeUploadTask) {
      this.unsubscribeUploadTask();
    }
  }

  // HELPER METHODS //

  //Function that fires when we click the buttons in the PendingListItem component.
  //It handles opening the appropriate dialog box tied to the appropriate route object.
  handleDialogAssignment = (pickupIndex, e) => {
    console.log(e.currentTarget.name, "NAMEEEEE");
    const pickups = this.props.pickupState.pending.slice();
    const typeNote = e.currentTarget.name;

    //Use pickupIndex argument that we receive from PendingListItem component to tie our dialog to the appropriate route object.
    const selectedPickup = pickups.find(
      (pickup) => pickup.index === pickupIndex
    );

    //clear photo always
    this.setState({ driverPhoto: null });

    //We use the name of our clicked target to assign the appropriate dialog component to this.dialogType,
    // which then renders if we set state on viewDialog to true.

    this.dialog = () => (
      <NoteDialog
        selectedPickup={selectedPickup}
        initialPickups={this.props.pickupState.raw}
        type={typeNote}
        index={pickupIndex}
        name={selectedPickup.address.name}
        handleSubmitNoteAndDiscard={this.handleSubmitNoteAndDiscard}
        handleCancelNoteAndDiscard={this.handleCancelNoteAndDiscard}
        handleDriverPhoto={this.handleDriverPhoto}
        generalInfo={this.props.generalInfo}
      />
    );

    //We set viewDialog to true which will render the component we have chosen.
    this.setState({ viewDialog: true });
  };

  // HANDLECOMPLETEANDDISCARD AND HELPERS //
  //Handles our clicks when dialog opens. Handles both discard and complete dialogs.
  //In case of discard dialog handles only accept button.
  handleCompleteAndDiscard = (index, e, status) => {
    const elName = e.currentTarget ? e.currentTarget.name : e.target.name;

    //If name of button is accept then complete or discard the route.
    if (elName === "accept") {
      // show spinner when clicking button
      this.setState({ showSpinner: true });
      const pendingPickups = this.props.pickupState.pending.slice();

      //Then we change our pending array in state to reflect the change of the completed or discarded property for the particular object we are working on.
      const pendingPickupsFiltered = pendingPickups.filter((pickup) => {
        return pickup.index !== index && pickup.alternative !== true;
      });

      //Find the selected pickup to show in our alert.
      const selectedPickup = pendingPickups.find(
        (pickup) => pickup.index === index
      );

      console.log(selectedPickup, "SELECTED PICKUP TO COMPLETE");

      // CHECKS

      //check concerning start location
      // if start location not complete before pressing any other pickups, return and show alert
      const startNotComplete = this.isStartComplete(
        selectedPickup,
        pendingPickups
      );

      if (startNotComplete) {
        this.setState({ showSpinner: false });
        return;
      }

      // check concerning end location
      // if not all pickups complete when pressing end location or losplats location, then return and show alert
      const notAllComplete = this.areAllPickupsCompleteBeforeEnd(
        selectedPickup,
        status,
        pendingPickupsFiltered
      );

      if (notAllComplete) {
        this.setState({ showSpinner: false });
        return;
      }

      //check if all locations apart from start location are within 300m of driver when completed(!)

      const withinRadius = isWithinRadius(selectedPickup, this.props.curPos);
      if (!withinRadius) {
        this.setState({
          viewDialog: false,
          showAlert: true,
          alertMessage: `Kan niet voltooien ${selectedPickup.address.name}!`,
          alertError:
            "Je moet dichterbij de locatie zijn om de locatie af te vinken.",
          alertStatus: "Failure",
          showSpinner: false 
        });
    
        return;
      }

      // WRITING STATE
      const userStatusFirestoreRef = this.props.firebase.db.doc(
        `/amsterdam/driverSummary/${this.props.authUser.uid}/routeProgress`
      );

      const docRef = this.props.firebase.route(
        this.props.authUser.uid,
        this.props.pickupState.date
      );

      const timestamp = this.props.firebase.timeStamp.now();

      // begin chain to update our state and write to firestore
      this.startRoute(
        selectedPickup,
        status,
        index,
        pendingPickupsFiltered,
        userStatusFirestoreRef,
        timestamp
      )
        .then((dataToWrite) => {
          if (selectedPickup.extraPickup) {
            return this.updateFirestoreExtraPickups(status, selectedPickup);
          } else {
            return this.updateFirestore(docRef, dataToWrite);
          }
        })
        .then(() => {
          // after we manage to update firestore, we want to start our internal timer
          // and also change the status of completed / pending
          return this.updateStatusAndTimer(
            selectedPickup,
            status,
            userStatusFirestoreRef
          );
        })
        .then(() => {
          this.writeLocalState(
            pendingPickupsFiltered,
            selectedPickup,
            status,
            timestamp
          );
        })
        .catch((error) => {
          // hide spinner
          this.setState({ showSpinner: false });
          //If we fail at any step of the chain then we handle error here
          const alertTarget =
            status === "completed" ? "voltooien" : "weggooien";
          this.setState({
            viewDialog: false,
            showAlert: true,
            alertMessage: `Er is iets mis gegaan bij het ${alertTarget} van deze locatie.`,
            alertError: `Error: ${error.message}`,
            alertStatus: "Failure",
          });
        });
    } else if (elName === "decline") {
      //If user declines to change anything, we simply close our dialog.
      this.setState({ viewDialog: false });
    } else if (elName === "postpone") {
      this.postponePickup(index);
    }
  };

  // handleCompleteAndDiscard helpers
  // helpers for checks
  areAllPickupsCompleteBeforeEnd = (selectedPickup, status, pendingPickups) => {
    if (
      selectedPickup.address.name === "Eind Locatie" &&
      status === "completed"
    ) {
      //If we haven't completed all the pickups including postpoened ones, we cannot finish and show end form.
      //Instead we show an alert

      if (
        !(pendingPickups.length === 0) ||
        this.props.postponedPickups.length > 0
      ) {
        this.setState({
          viewDialog: false,
          showAlert: true,
          alertMessage: `Voltooi eerst alle ophaallocaties.`,
          alertError:
            "Voor het voltooien van de eindlocaties moeten alle ophaallocaties voltooid worden. ",
          alertStatus: "Failure",
        });
        return true;
      }
    } else if (
      selectedPickup.address.name === "Losplaats" &&
      status === "completed"
    ) {
      if (
        // when clicking the losplaats it should just include the end location, and no postponed pickups
        !(pendingPickups.length === 1) ||
        this.props.postponedPickups.length > 0
      ) {
        this.setState({
          viewDialog: false,
          showAlert: true,
          alertMessage: `Voltooi eerst alle ophaallocaties.`,
          alertError:
            "Voor het voltooien van de eindlocaties moeten alle ophaallocaties voltooid worden. ",
          alertStatus: "Failure",
        });
        return true;
      }
    }
  };

  isStartComplete = (selectedPickup, pendingPickups) => {
    if (
      selectedPickup.address.name !== "Start Locatie" &&
      pendingPickups.find((pickup) => pickup.address.name === "Start Locatie")
    ) {
      //If we haven't completed start locatie then don't allow user to do anything.

      this.setState({
        viewDialog: false,
        showAlert: true,
        alertMessage: 'Vul alstublieft "Start Locatie" in. ',
        alertError:
          'Voordat u andere ophalingen verwerkt, moet u "Start Locatie" voltooien. ',
        alertStatus: "Failure",
      });
      return true;
    }
  };

  // helpers for writing state
  startRoute = (
    selectedPickup,
    status,
    index,
    pendingPickupsFiltered,
    userStatusFirestoreRef,
    timestamp
  ) => {
    //Constructing string to be used to write to firestore.
    //We use the index as key and the status variable ('completed' or 'discarded') to find the appropriate object in our document and update it.

    const pickupStatus = `pickups.${index}.${status}`;
    const dataToWriteSingle = {
      //e.g. 'pickups.0.completed' will update to true.
      [pickupStatus]: true,
      //e.g. 'pickups.0.time' will update to current time.
      [`pickups.${index}.time`]: timestamp,
    };
    //  check if there are asscociated pickups with this address and if so add them to the doc write
    if (selectedPickup.associatedPickups) {
      const extraPickupData = selectedPickup.associatedPickups.map(
        (pickupIndex) => {
          return {
            [`pickups.${pickupIndex}.${status}`]: true,
            [`pickups.${pickupIndex}.time`]: timestamp,
          };
        }
      );

      var dataToWriteWithExtras = extraPickupData.reduce((acc, pickup) => {
        return { ...acc, ...pickup };
      }, dataToWriteSingle);
    }

    const dataToWrite = dataToWriteWithExtras
      ? dataToWriteWithExtras
      : dataToWriteSingle;

    //check if our timer has already started and if write timestamp to firestore
    if (
      selectedPickup.address.name === "Start Locatie" &&
      status === "completed" &&
      !this.props.timerStarted
    ) {
      // write in firestore the number of pending locations

      const remainingLocations = {
        pending: this.props.pickupState.raw.length - 4, // - 3 to account for start and end location and unload locations and pauze
        completed: 0,
      };

      return userStatusFirestoreRef
        .set(remainingLocations)
        .then(() => dataToWrite); // returning a promise so we can then chain on the rest of our async code
      // where we also pass the updated dataToWrite for start location
    }

    return Promise.resolve(dataToWrite); // if we are not staring route then just resolve empty promise to keep chain going
  };

  updateFirestore = (docRef, dataToWrite) => {
    return this.props.firebase.db.runTransaction((transaction) => {
      return transaction.get(docRef).then((snapshot) => {
        if (!snapshot.exists) {
          throw "Document does not exist.";
        }
        // return promise to make its handling part of chain
        return transaction.update(docRef, { ...dataToWrite });
      });
    });
  };

  updateFirestoreExtraPickups = (status, extraPickup) => {
    return this.props.firebase.db
      .collection("amsterdam")
      .doc("routes")
      .collection(this.props.pickupState.date)
      .doc("pickups")
      .collection("extraPickups")
      .doc(extraPickup.extraPickupId)
      .update({ [status]: true, time: this.props.firebase.timeStamp.now() });
  };

  updateStatusAndTimer = (selectedPickup, status, userStatusFirestoreRef) => {
    if (
      selectedPickup.address.name === "Start Locatie" &&
      status === "completed" &&
      !this.props.timerStarted
    ) {
      this.props.startTimer();
    } else if (
      selectedPickup.address.name !== "Eind Locatie" &&
      selectedPickup.address.name !== "Losplaats" &&
      selectedPickup.address.name !== "Pauze"
    ) {
      // if its not start location then we can subtract from pending and completed status
      // if pickup has associated contracts we need to acccount for them
      return userStatusFirestoreRef.update({
        completed: selectedPickup.associatedPickups
          ? this.props.firebase.FieldValue.increment(
              1 + selectedPickup.associatedPickups.length
            ) // 1 for the current pickup + the associated contracts
          : this.props.firebase.FieldValue.increment(1),
        pending: selectedPickup.associatedPickups
          ? this.props.firebase.FieldValue.increment(
              -1 - selectedPickup.associatedPickups.length
            ) // 1 for the current pickup + the associated contracts
          : this.props.firebase.FieldValue.increment(-1),
      });
    }
  };

  writeLocalState = (
    pendingPickupsFiltered,
    selectedPickup,
    status,
    timestamp
  ) => {
    this.props.dispatchPickupState({
      type: "PROCESS_PICKUP",
      payload: {
        selectedPickup: {
          ...selectedPickup,
          time: timestamp,
          [status]: true,
        },
        curPos: this.props.curPos,
      },
    });

    //We set state we the new array containing changes, which will lead to a re-render of our component.
    this.setState(
      {
        //We close our dialog.
        viewDialog: false,
        showAlert: true,
        alertMessage: `${selectedPickup.address.name} ${
          status === "completed" ? "voltooid" : "weggegooid"
        }.`,
        alertStatus: "Success",
        alertError: "",
        showSpinner: false, // hide spinner
      },
      () => {
        if (
          selectedPickup.address.name === "Eind Locatie" &&
          status === "completed"
        ) {
          this.props.showFormEnd();
        }
      }
    );
  };

  postponePickup = (index) => {
    const pendingPickups = this.props.pickupState.pending.slice();

    //Then we change our pending array in state to reflect the change of the completed or discarded property for the particular object we are working on.
    const pendingPickupsFiltered = pendingPickups.filter((pickup) => {
      return pickup.index !== index && pickup.alternative !== true;
    });

    //Find the selected pickup to show in our alert.
    const selectedPickup = pendingPickups.find(
      (pickup) => pickup.index === index && pickup.alternative !== true
    );

    this.props.dispatchPickupState({
      type: "SET_CLOSEST",
      payload: { curPos: this.props.curPos, pending: pendingPickupsFiltered },
    });

    this.props.postponePickup(selectedPickup);
  };

  //HANDLESUBMITNOTEANDDISCARD AND HELPERS
  //Fires when user clicks submit button on our NoteDialog or our DiscardDialog.
  //Function accepts index of selected pickup, event that fires on form submission and an isDiscarded argument that can be true of false.
  handleSubmitNoteAndDiscard = (
    pickupIndex,
    e,
    isDiscarded,
    driverCustomMsg,
    driverAutoMsg
  ) => {
    // show spinner
    this.setState({ showSpinner: true });
    const pickups = this.props.pickupState.pending.slice();
    //Use pickupIndex argument that we receive from PendingListItem component to tie our dialog to the appropriate route object.
    const selectedPickup = pickups.find((pickup) => {
      //We get back an array, we only on key on each iteration.

      //And so we always want to check against the first and only key.
      return pickup.index === pickupIndex;
    });
    // check for location
    //check if all locations apart from start location are within 300m of driver when completed(!)
    if (isDiscarded) {
      //check concerning start location
      // if start location not complete before pressing any other pickups, return and show alert
      const startNotComplete = this.isStartComplete(selectedPickup, pickups);

      if (startNotComplete) {
        this.setState({ showSpinner: false });
        return;
      }

      //check if you are within radius
      const withinRadius = isWithinRadius(selectedPickup, this.props.curPos);
      if (!withinRadius) {
        this.setState({
          viewDialog: false,
          showAlert: true,
          alertMessage: `Kan niet voltooien ${selectedPickup.address.name}!`,
          alertError:
            "Je moet dichterbij de locatie zijn om de locatie af te vinken.",
          alertStatus: "Failure",
          showSpinner: false 
        });
        return;
      }
    }

    console.log("HANDLE AND SUBMIT FIRES");
    e.preventDefault();

    //CHECKS
    // if  msg is empty show alert and return
    if (
      driverAutoMsg.type === "overig" &&
      driverCustomMsg.trim().length === 0
    ) {
      this.setState({
        viewDialog: false,
        showAlert: true,
        alertMessage: `U kunt geen leeg bericht indienen.`,
        alertError: `Probeer nog een keer.`,
        alertStatus: "Failure",
        showSpinner: false,
      });
      return;
    }

    // if  possibly mandatory photo is null
    if (
      driverAutoMsg.photoMandatory &&
      this.state.driverPhoto === null
    ) {
      this.setState({
        viewDialog: false,
        showAlert: true,
        alertMessage: `Foto is verplicht`,
        alertError: `Een foto toevoegen is verplicht voor deze melding`,
        alertStatus: "Failure",
        showSpinner: false,
      });
      return;
    }

    // start our chain
    Promise.resolve()
      // starting chain with a resolved promise so that any errors thrown in writePhotoStorage's synchronous computations are handled by the catch block in then()
      .then(() => {
        // if we are dealing with discarding distribution items we need to
        // first makes changes to the respective items in the distribution collection
        if (selectedPickup.distributionPickup && isDiscarded) {
          let allPickups;

          if (selectedPickup.associatedPickups) {
            allPickups = selectedPickup.associatedPickups.reduce(
              (acc, pickupIndex) => {
                const associatedPickup = this.props.pickupState.raw.find(
                  (pickup) => pickup.index === pickupIndex
                );
                return [...acc, associatedPickup];
              },
              [selectedPickup]
            );
          } else {
            allPickups = [selectedPickup];
          }

          const proms = allPickups.map((pickup) =>
            this.props.firebase.updateDistribution(pickup.distrPickupId, {
              pickupDate: null,
              planned: false,
            })
          );

          return Promise.all(proms);
        }
      })
      .then(() => {
        return this.writePhotoStorage(this.state.driverPhoto);
      })
      .then(
        (photoSnapshot) => {
          return this.writeMsgFirestore(
            photoSnapshot,
            isDiscarded,
            selectedPickup,
            driverCustomMsg,
            driverAutoMsg
          );
        },
        (error, photoSnapshot) => {
          // if there is an error uploading photo, we show error message,
          // but keep chaining going to be able to store message even though photo didnt upload
          this.setState({ uploadError: error.message });
          return this.writeMsgFirestore(
            photoSnapshot,
            isDiscarded,
            selectedPickup,
            driverCustomMsg,
            driverAutoMsg
          );
        }
      )

      .then(() => {
        //If event fires from our DiscardDialog, we still have to update our firestore and our pending list in our state.
        if (isDiscarded) {
          //So we simply handleCompleteAndDiscard with a fake event with currentTarget.name === 'accept' to simulate a button click with name='accept'.
          //Then the function handles writing to firestore and updating our state with a status of discarded for our selected pickup.
          const fakeEvent = { currentTarget: { name: "accept" } };
          this.handleCompleteAndDiscard(pickupIndex, fakeEvent, "discarded");
        }
      })
      .catch((error) => {
        // hide spinner in case of error
        this.setState({ showSpinner: false });
        console.log(error, "ERRROR");
        //Handle all errors from chain here
        //If we succesfully write to firestore then we close the dialog and reset message state.
        this.setState({
          viewDialog: false,
          showAlert: true,
          alertMessage: `Er is iets misgegaan bij het maken van de melding. Probeer nog een keer. `,
          alertError: `Error: ${error.message}`,
          alertStatus: "Failure",
        });
      });
  };

  // handleSubmitNoteAndDiscard helpers
  writePhotoStorage = (driverPhoto) => {
    if (driverPhoto) {
      const timestamp = Date.now();
      const uploadTask = this.props.firebase.storageRef
        .child(`driverPhotos/${this.props.authUser.uid}-${timestamp}`)
        .put(driverPhoto);
      this.setState({ uploadTask });

      return uploadTask;
    } else {
      // if we dont have a photo we simply start teh chain returning an resolverd promise
      return Promise.resolve();
    }
  };

  writeMsgFirestore = (
    photoSnapshot,
    isDiscarded,
    selectedPickup,
    driverCustomMsg,
    driverAutoMsg
  ) => {
    // check if selected pickup has associated pickups
    let allPickups;

    if (
      selectedPickup.associatedPickups &&
      !selectedPickup.distributionPickup
    ) {
      allPickups = selectedPickup.associatedPickups.reduce(
        (acc, pickupIndex) => {
          const associatedPickup = this.props.pickupState.raw.find(
            (pickup) => pickup.index === pickupIndex
          );
          return [...acc, associatedPickup];
        },
        [selectedPickup]
      );
    } else {
      // if there are no associated pickups or if we have a distribution pickup,
      // then allPickups is simply one selected pickup
      allPickups = [selectedPickup];
    }

    const proms = allPickups.map((pickup) => {
      const notif = {
        app: "dispatchx",
        clientId: pickup.address.userId,
        contractId: pickup.id,
        clientName: pickup.address.name,
        street: pickup.address.street,
        garbageType: pickup.address.garbageType,
        description: driverCustomMsg ? driverCustomMsg : null,
        createdBy: this.props.driverDetails.name,
        created: this.props.firebase.timeStamp.now(),
        class: driverAutoMsg.class,
        type: driverAutoMsg.type,
        resolvable: driverAutoMsg.resolvable,
        resolved: driverAutoMsg.resolvable ? false : true,
        accepted:
          driverAutoMsg.class === "distributie verwerpen"
            ? false
            : driverAutoMsg.resolvable
            ? false
            : true,
        canResolve: driverAutoMsg.canResolve,
        photo: photoSnapshot ? photoSnapshot.ref.location.path_ : null, // if there is a photo add it to the object
      };

      if (pickup.distributionPickup) {
        notif.distrPickupId = pickup.distrPickupId;
      }
      return this.props.firebase.addNotification(notif);
    });

    // write the notification
    return Promise.all(proms).then(() => {
      if (!isDiscarded) {
        this.setState({
          viewDialog: false,
          driverCustomMsg: "",
          driverTypeMsg: null,
          driverPhoto: null,
          showAlert: true,
          alertMessage: `Melding voor ${selectedPickup.address.name} ingediend.`,
          alertStatus: "Success",
          alertError: "", //Pass it empty so that alert component can render properly(it depends on whether error exists to render)
          showSpinner: false, // also hide spinner
        });
      }
    });
  };

  //If user click cancel in our NoteDialog we simply close dialog.
  handleCancelNoteAndDiscard = (index, e) => {
    this.setState({
      viewDialog: false,
    });
  };

  handleDriverPhoto = (photo) => {
    this.setState({ driverPhoto: photo });
  };

  hideAlert = () => {
    if (this.state.showAlert) {
      return setTimeout(() => {
        this.setState({ showAlert: false });
      }, 2000);
    }
  };

  closeAlert = () => {
    this.setState({ showAlert: false });
  };

  handleGmapsLandMode = (lat, lng) => {
    // when button for route is clicked in landscape mode this func fires
    console.log("gmaps handlers fired", lat, lng);
    // sets the showGoogleMaps state to true
    // also
    this.setState({
      showGmapsLandMode: true,
      GmapsLandModeLat: lat,
      GmapsLandModeLng: lng,
    });
  };

  closeGmapsLandMode = () => {
    this.setState({
      showGmapsLandMode: false,
    });
  };

  render() {
    const {
      pending,
      showAlert,
      alertMessage,
      alertStatus,
      alertError,
      showGmapsLandMode,
      GmapsLandModeLat,
      GmapsLandModeLng,
      viewDialog,
      uploadTaskProgress,
      uploadError,
      showSpinner,
    } = this.state;

    const { windowWidth, curPos, shiftTimerStarted, shiftStart } = this.props;

    console.log("alert st in pending", showAlert);
    return (
      <main>
        <section
          className="section-pending "
          ref={(node) => (this.pendingSection = node)}
        >
          {/* If there are routes in our array then render PendingList component. */}
          {this.props.pickupState.pending !== null &&
            curPos &&
            this.props.generalInfo && (
              <PendingList
                pending={this.props.pickupState.pending}
                initialPickups={this.props.pickupState.raw}
                handleDialogAssignment={this.handleDialogAssignment}
                currentPosition={curPos}
                shiftTimerStarted={shiftTimerStarted}
                shiftStart={shiftStart}
                handleCompleteAndDiscard={this.handleCompleteAndDiscard}
                windowWidth={windowWidth}
                handleGmapsLandMode={this.handleGmapsLandMode}
                driverDetails={this.props.driverDetails}
                generalInfo={this.props.generalInfo}
              />
            )}

          {/* If viewDialog is set to true render appropriate dialog. */}

          {viewDialog && this.dialog()}

          {showAlert && (
            <SwalNotification
              message={alertMessage}
              alertStatus={alertStatus}
              error={alertError}
              closeAlert={this.closeAlert}
            />
          )}
          {/* If window size is bigger than a set value, then render our second component for landscape view. */}
          {this.props.pickupState.pending &&
            this.props.generalInfo &&
            this.props.pickupState.pending.length > 0 &&
            this.props.curPos &&
            windowWidth > 900 &&
            !showGmapsLandMode && (
              <PendingLandMode
                pending={this.props.pickupState.pending}
                initialPickups={this.props.pickupState.raw}
                handleDialogAssignment={this.handleDialogAssignment}
                currentPosition={curPos}
                handleCompleteAndDiscard={this.handleCompleteAndDiscard}
                handleGmapsLandMode={this.handleGmapsLandMode}
                driverDetails={this.props.driverDetails}
                generalInfo={this.props.generalInfo}
              />
            )}

          {this.props.pickupState.pending &&
            this.props.generalInfo &&
            this.props.pickupState.pending.length > 0 &&
            this.props.curPos &&
            windowWidth > 900 &&
            showGmapsLandMode && (
              <GoogleMapsPage
                lat={GmapsLandModeLat}
                lng={GmapsLandModeLng}
                landscapeMode
                closeGmapsLandMode={this.closeGmapsLandMode}
              />
            )}

          {uploadTaskProgress && (
            <Dialog
              title={"Afbeelding uploaden"}
              handleClose={() => this.setState({ uploadTaskProgress: null })}
            >
              <div className={"dialog__progress-shower-container"}>
                <p className={"dialog__progress-shower-text"}>
                  {uploadTaskProgress} %
                </p>
              </div>
            </Dialog>
          )}
          {uploadError && (
            <Dialog
              title={"Fout bij uploaden afbeelding"}
              handleClose={() => this.setState({ uploadError: null })}
            >
              <div className={"dialog__progress-shower-container"}>
                <p className={"dialog__progress-shower-text"}>{uploadError}</p>
              </div>
            </Dialog>
          )}
          {showSpinner && <Loader message={"Laden..."} actionsLoader />}
        </section>
      </main>
    );
  }
}

const PendingPageWithCurPos = compose(
  withCurrentPosition,
  withPickupStore
)(PendingPage);

//If the condition fails, we redirect to Login page.
const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(PendingPageWithCurPos);
