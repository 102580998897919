import React from "react";
import { compose } from "recompose";

import ShowRoute from "./showRoute";

import { withGoogleAPI } from "./withGoogleAPI";
import { withAuthorization } from "../Session";
import { withCurrentPosition } from "../Geolocation";

const MapContainer = (props) => {
  let lat;
  let lng;

  if (props.match.params.coords) {
    const { coords } = props.match.params;
    const target = coords.split(",");
    lat = target[0];
    lng = target[1];
  } else {
    lat = props.lat;
    lng = props.lng;
  }
console.log('i updated')
  return (
    <ShowRoute
      windowHeight={props.windowHeight}
      google={props.google}
      firebase={props.firebase}
      authUser={props.authUser}
      targetLocation={{ lat: Number(lat), lng: Number(lng) }}
      currentLocation={props.curPos}
      landscapeMode={props.landscapeMode}
      closeGmapsLandMode={props.closeGmapsLandMode}
    />
  );
};

//If the condition fails, we redirect to Login page.
const condition = (authUser) => !!authUser;
//Wrapping our MapContainer with GoogleApiWrapper HOC
const GoogleMapsPageWithPos = compose(
  withGoogleAPI,
  withCurrentPosition
)(MapContainer);

const GoogleMapsPageWithAuth = withAuthorization(condition)(
  GoogleMapsPageWithPos
);

export default GoogleMapsPageWithAuth;
