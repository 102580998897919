import React from "react";

export const Textarea = ({
  rows,
  cols,
  className,
  name,
  placeholder,
  value,
  onChange,
  label,
}) => (
  <>
    {label && <label>{label}</label>}
    <textarea
      rows={rows}
      cols={cols}
      className={className}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  </>
);
