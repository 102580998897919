import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import Alert from "../Alert";
import SwalNotification from "../Swal";

import AuthUserContext from "./context";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

//HOC that receives a condition function as a parameter and decides if it should redirect to a public route, or render the passed component.
const withAuthorization = (condition) => (Component) => {
  class withAuthorization extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        showAlert: false,
        alertMessage: "",
        alertStatus: "",
        alertError: "",
      };
    }

    componentDidMount() {
      this.unsubcribe = this.props.firebase.auth.onAuthStateChanged(
        (authUser) => {
          if (!condition(authUser)) {
            this.props.history.push(ROUTES.LOGIN);
          }
        },
        (error) =>
          this.setState({
            showAlert: true,
            alertStatus: "Failure",
            alertMessage: `Kon uw inloggegevens niet verifiëren. Vernieuw de pagina en probeer het opnieuw. `,
            alertError: `Error: ${error.message}`,
          })
      );
    }

    componentWillUnmount() {
      //onAuthStateChanged function returns a function to us that unsubscribes the function we originally gave it.
      this.unsubcribe();
    }

    closeAlert = () => {
      this.setState({ showAlert: false });
    };

    render() {
      const { showAlert, alertError, alertMessage, alertStatus } = this.state;
      console.log("WITH AUTHORIZATION COMPONENT RENDERING +++++++++++++++");

      return (
        <>
          {showAlert && (
            <SwalNotification
              message={alertMessage}
              alertStatus={alertStatus}
              error={alertError}
              closeAlert={this.closeAlert}
            />
          )}
          {/* Adding consumer component so that authUser value is available to us even before onAuthChange listener redirects.
					 As such we avoid showing the protected page before the redirect happens. */}
          <AuthUserContext.Consumer>
            {(authUser) =>
              condition(authUser) ? (
                <Component authUser={authUser} {...this.props} />
              ) : null
            }
          </AuthUserContext.Consumer>
        </>
      );
    }
  }
  //Using recompose library to wrap 'withAuthorization' HOC with 'withRouter' and 'withFirebase' HOCs
  return compose(withRouter, withFirebase)(withAuthorization);
};

export default withAuthorization;
