import React from "react";

import ListNoResults from "../ListNoResults";
import Search from "../Search";
import CompletedListItem from "./CompletedListItem";

const CompletedList = (props) => {
  const processedPickups = props.processed;
  if (processedPickups === undefined) {
    return (
      <ListNoResults
        message={"Er zijn geen locaties in de database voor deze datum. "}
      />
    );
  }
  //If there no pickups coming in from firestore we show a no pickups message and return from function.
  //But always remove start and end location from array first
  const processedPickupsNoStartEnd = processedPickups.filter(
    (pickup) =>
      !(
        pickup.address.name === "Start Locatie" ||
        pickup.address.name === "Eind Locatie" ||
        pickup.address.name === "Losplaats" ||
        pickup.address.name === "Pauze"
      )
  );
  if (processedPickupsNoStartEnd.length === 0) {
    return <ListNoResults message={"Er zijn nog geen locaties voltooid. "} />;
  }

  // sort pickups based on completion time
  const sortedPickups = processedPickupsNoStartEnd.sort(
    (a, b) => b.time.seconds - a.time.seconds
  );

  //If we get past the check for no pickups, we want to filter with our search input results.
  //We show our search only if we have more than two pickups, so we check.

  if (sortedPickups.length > 2) {
    var processedPickupsFiltSearch = sortedPickups.filter(
      (pickup) =>
        pickup.address.name
          .toLowerCase()
          .includes(props.searchInput.toLowerCase()) ||
        pickup.address.street
          .toLowerCase()
          .includes(props.searchInput.toLowerCase())
    );
  }

  //if processedPickupsFiltSearch is undefined (if we have less than three pickups) we want to use processedPickups to map our list
  const processedPickupsToShow = processedPickupsFiltSearch
    ? processedPickupsFiltSearch
    : sortedPickups;

  //If  filtered pickups from search exist,we can go ahead and construct our list
  if (processedPickupsToShow.length > 0) {
    var processedPickupsList = processedPickupsToShow.map((pickup, index) => {
      return (
        <CompletedListItem
          key={`${pickup.address.street}-${pickup.address.name}${
            pickup.extraPickup ? `-extra-${pickup.created.seconds}` : ""
          }`}
          value={pickup}
          date={props.date}
          actualIndex={index}
          currentPosition={props.currentPosition}
          recoverPickup={props.recoverPickup}
        />
      );
    });
  }

  return (
    <div className={"pickup__list-container pickup__list-container--completed"}>
      {processedPickupsNoStartEnd.length > 2 && (
        <Search
          input={props.searchInput}
          handleChange={props.handleSearchInput}
        />
      )}

      {/* If our list exists it means there are results from search and we show them. If not we show a no pickups
			message. */}
      {processedPickupsList ? (
        <ul className="pickup__list pickup__list--completed">
          {processedPickupsList}
        </ul>
      ) : (
        <ListNoResults message={"Er zijn geen pickups gevonden. "} />
      )}
    </div>
  );
};

export default CompletedList;
