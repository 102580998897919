import React from "react";
// import { Link } from 'react-router-dom';

// import * as ROUTES from '../../constants/routes';
import { withAuthorization } from "../Session";

//Icons component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Loader from "../Loader";
import Alert from "../Alert";
import SwalNotification from "../Swal";

class SettingsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      user: null,
      showAlert: false,
      alertMessage: "",
      alertStatus: "",
      alertError: "",
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    //Retrieving individual user's data
    this.props.firebase
      .user(this.props.authUser.uid)
      .get()
      .then((snapshot) => {
        this.setState({
          user: snapshot.data(),
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          showAlert: true,
          alertStatus: "Failure",
          alertMessage: `Could not retrieve profile info. Please refresh page and try again.`,
          alertError: `Error: ${error.message}`,
        });
      });
  }

  closeAlert = () => {
    this.setState({ showAlert: false });
  };

  render() {

    const {
      user,
      loading,
      showAlert,
      alertError,
      alertMessage,
      alertStatus,
    } = this.state;

    return (
      <main>
        <section className="section-settings">
          <div className="settings">
            {showAlert && (
              <SwalNotification
                message={alertMessage}
                alertStatus={alertStatus}
                error={alertError}
                closeAlert={this.closeAlert}
              />
            )}
            {loading && <Loader message={"Profiel laden..."} />}

            {user && (
              <UserInfo
                user={user}
                softwareVersion={this.props.softwareVersion}
              />
            )}
          </div>
        </section>
      </main>
    );
  }
}

const UserInfo = ({ user, softwareVersion }) => {
  return (
    <ul className="settings__list">
      <li key={user.email} className="settings__list-item">
        <FontAwesomeIcon className="settings__icon" icon="envelope" />
        <p className="settings__paragraph">{user.email}</p>
      </li>
      <li key={user.name} className="settings__list-item">
        <FontAwesomeIcon className="settings__icon" icon="user" />
        <p className="settings__paragraph"> {user.name} </p>
      </li>
      <li key={user.phone} className="settings__list-item">
        <FontAwesomeIcon className="settings__icon" icon="phone-alt" />
        <p className="settings__paragraph"> {user.phone} </p>
      </li>
      <li key={"version"} className="settings__list-item">
        <FontAwesomeIcon className="settings__icon" icon="wrench" />
        <p className="settings__paragraph"> version {softwareVersion} </p>
      </li>
      {/* <Link className="settings__link" to={ROUTES.PASSWORD_FORGET}>
				<li className="settings__list-item">
					<FontAwesomeIcon className="settings__icon" icon="user-clock" />
					<p className="settings__paragraph"> I forgot my password </p>
					<FontAwesomeIcon className="settings__icon settings__icon--arrow" icon="chevron-right" />
				</li>
			</Link>
			<Link className="settings__link" to={ROUTES.PASSWORD_CHANGE}>
				<li className="settings__list-item">
					<FontAwesomeIcon className="settings__icon" icon="user-lock" />
					<p className="settings__paragraph"> Change my password</p>
					<FontAwesomeIcon className="settings__icon settings__icon--arrow" icon="chevron-right" />
				</li>
			</Link>
			<li className="settings__list-item">
				<FontAwesomeIcon className="settings__icon" icon="globe" />
				<p className="settings__paragraph">
					Set language
					<span className="settings__paragraph--sub">NL / EN</span>
				</p>
				<FontAwesomeIcon className="settings__icon settings__icon--arrow" icon="chevron-right" />
			</li> */}
    </ul>
  );
};

//If the condition fails, we redirect to Login page.
const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(SettingsPage);
