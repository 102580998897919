import React, { useState } from "react";
import { Link } from "react-router-dom";
import { convertDistance } from "geolib";
import ContainerPicker from "../Pending/ContainerPicker";
import DistribItemsPicker from "../Pending/DistribItemsPicker";

//Icons component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dialog from "../Dialog";

const PendingLandModeListItem = (props) => {
  const [showDistributionPicker, setShowDistributionPicker] = useState(false);

  const [showContainers, setShowContainers] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showPhone, setShowPhone] = useState(false);

  const distanceInKm = convertDistance(
    props.value.distFromTarget,
    "km"
  ).toFixed(2);
  const isGarage =
    props.value.address.name === "Start Locatie" ||
    props.value.address.name === "Eind Locatie" ||
    props.value.address.name === "Losplaats" ||
    props.value.address.name === "Pauze"
      ? true
      : false;

        // if pickup is distribution we use slightly different options for completing, discrding, and comments
  const isDistribution = props.value.distributionPickup;

  return (
    <>
      <li className={`pickup ${isGarage ? "pickup--garage" : ""}`}>
        <div className="pickup__address-container">
          <p className="pickup__name">
            {props.value.address.name} - {distanceInKm} km
          </p>

          {props.value.timewindow && (
            <p className="pickup__timewindow">
              {" "}
              <FontAwesomeIcon
                className="pickup__icon"
                icon={["far", "clock"]}
              />
              {props.value.timewindow}{" "}
            </p>
          )}

          <p className="pickup__street"> {props.value.address.street}</p>
        </div>
        <div className="pickup__second-row">
          {props.windowWidth < 900 ? (
            <Link
              className="pickup__link pickup__link--gmaps-btn"
              to={`g-maps/${props.value.address.lat},${props.value.address.lng}`}
            >
              Route{" "}
              <FontAwesomeIcon
                className="pickup__link--icon"
                icon="directions"
              />
            </Link>
          ) : (
            <>
              <button
                type="button"
                className="pickup__link pickup__link--gmaps-btn"
                onClick={() =>
                  props.handleGmapsLandMode(
                    props.value.address.lat,
                    props.value.address.lng
                  )
                }
              >
                <FontAwesomeIcon
                  className="pickup__link--icon"
                  icon="directions"
                />
              </button>
            </>
          )}

          {/* Depending on which button user clicks, handleDialogAssignment handles which dialog will pop up depending on button name attribute. */}
          {!isGarage && (
            <>
              <span className="pickup__link--divider" />
              <button
                className="pickup__link pickup__link--note-btn"
                name="note"
                onClick={(e) => props.handleDialogAssignment(props.index, e)}
              >
                <FontAwesomeIcon
                  className="pickup__link--icon"
                  icon={["far", "file-alt"]}
                />
              </button>
            </>
          )}

          {(props.value.address.pickupDetails ||
            props.value.backOfficeMessage) && (
            <>
              <span className="pickup__link--divider" />
              <button
                className="pickup__link pickup__link--details-btn"
                name="info"
                onClick={() => setShowDetails(true)}
              >
                <FontAwesomeIcon
                  className="pickup__link--icon"
                  icon={"info-circle"}
                />
              </button>{" "}
            </>
          )}
           {isDistribution && props.value.address.phone && (
                <>
                  <span className="pickup__link--divider" />
                  <button
                    className="pickup__link pickup__link--phone-btn"
                    name="phone"
                    onClick={() => setShowPhone(true)}
                  >
                    <FontAwesomeIcon
                      className="pickup__link--icon"
                      icon={"phone-alt"}
                    />
                  </button>
                </>
              )}
        </div>
        <div
          className={`pickup__right-column ${
            isGarage ? "pickup__right-column--garage" : ""
          }`}
        >
          {isGarage ? null : (
            <button
              className="pickup__btn pickup__btn--discard"
              name="discard"
              onClick={(e) => props.handleDialogAssignment(props.index, e)}
            >
              <FontAwesomeIcon className="pickup__btn--icon" icon="times" />{" "}
            </button>
          )}
          <button
            className={` pickup__btn pickup__btn--complete  ${
              isGarage ? "pickup__btn--garage" : ""
            }`}
            name="accept"
            onClick={
              isGarage
                ? (e) =>
                    props.handleCompleteAndDiscard(props.index, e, "completed")
                : isDistribution
                ? () => setShowDistributionPicker(true) // then check if it is a distribution item
                : () => setShowContainers(true)
            }
          >
            <FontAwesomeIcon className="pickup__btn--icon" icon="check" />
          </button>
        </div>
      </li>
      {showContainers && (
        <ContainerPicker
          initialPickups={props.initialPickups}
          selectedPickup={props.value}
          handleClose={() => setShowContainers(false)}
          handleCompleteAndDiscard={props.handleCompleteAndDiscard}
          pickupIndex={props.index}
          driverDetails={props.driverDetails}
        />
      )}
       {showDistributionPicker && (
        <DistribItemsPicker
          initialPickups={props.initialPickups}
          selectedPickup={props.value}
          pickupIndex={props.index}
          handleClose={() => setShowDistributionPicker(false)}
          handleCompleteAndDiscard={props.handleCompleteAndDiscard}
          generalInfo={props.generalInfo}
          driverDetails={props.driverDetails}

        />
      )}
      {showDetails && (
        <Dialog
          handleClose={() => setShowDetails(false)}
          title="Details voor "
          subtitle={props.value.address.name}
        >
          {props.value.address.pickupDetails && (
            <p className={"paragraph"}> {props.value.address.pickupDetails}</p>
          )}

          {props.value.backOfficeMessage && (
            <p className={"paragraph u-margin-top-small"}>
              {" "}
              <strong>Backoffice bericht:</strong>{" "}
              {props.value.backOfficeMessage}
            </p>
          )}
          <button
            className="btn-dialog btn-dialog--decline"
            type="button"
            onClick={() => setShowDetails(false)}
          >
            Sluit
          </button>
        </Dialog>
      )}
       {showPhone && (
        <Dialog
          handleClose={() => setShowPhone(false)}
          title="Telefoon voor "
          subtitle={props.value.address.name}
        >
          <p className={"paragraph"}> {props.value.address.phone}</p>

          <button
            className="btn-dialog btn-dialog--decline"
            type="button"
            onClick={() => setShowPhone(false)}
          >
            Sluit
          </button>
        </Dialog>
      )}
    </>
  );
};

export default PendingLandModeListItem;
