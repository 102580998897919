import React from "react";
import { GoogleApiWrapper } from "google-maps-react";
import GoogleAPIContext from "./context";

import Loader from "../Loader";

const withGoogleAPIWrapper = (Component) => {
  class withGoogleAPIWrapper extends React.Component {
    render() {
    console.log("GOOGLE MAPS COMPONENET RENDERING +++++++++++++++");

      return (
        <GoogleAPIContext.Provider value={this.props.google}>
          <Component {...this.props} />
        </GoogleAPIContext.Provider>
      );
    }
  }
  //Wrapping HOC 'withAuthUser' with HOC 'withFirebase' so that an instance of Firebase becomes available in it.
  return GoogleApiWrapper({
    apiKey: "AIzaSyCYAlbdT4UzYVpfUKhwVbbN5AzxB4Y2LSA&",
    LoadingContainer: Loader,
  })(withGoogleAPIWrapper);
};

export default withGoogleAPIWrapper;
