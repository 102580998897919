import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Search = ({ input, handleChange }) => (
	<div className="search__container">
		<input className="search" type="text" name="search" value={input} onChange={handleChange} placeholder="Voer de naam of het adres van de klant in..."/>
		<div className="search__container--copy" />
		<FontAwesomeIcon className="search__icon" icon="search" />
	</div>
);

export default Search;
