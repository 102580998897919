import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getDistance, convertDistance } from "geolib";
import ContainerPicker from "./ContainerPicker";
import Dialog from "../Dialog";
import withTimeStore from "../TimeStore/withTimeStore";
import { convertTimeToSecs } from "../TimeStore/helpers";
import DistribItemsPicker from "./DistribItemsPicker";

//Icons component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PendingListItem = (props) => {
  const [showDistributionPicker, setShowDistributionPicker] = useState(false);
  const [showContainers, setShowContainers] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showPhone, setShowPhone] = useState(false);

  //This is the index of item in the array we 're looping over. Not to be confused with index of pickup in firestore.
  const actualIndex = props.actualIndex;
  const { latitude, longitude } = props.currentPosition;
  const distanceFromTarget = getDistance(
    { latitude, longitude },
    {
      latitude: props.value.address.lat,
      longitude: props.value.address.lng,
    }
  );
  const distanceInKm = convertDistance(distanceFromTarget, "km").toFixed(2);
  const alternative = props.value.alternative ? "alt" : null;
  const isGarage =
    props.value.address.name === "Start Locatie" ||
    props.value.address.name === "Eind Locatie" ||
    props.value.address.name === "Losplaats" ||
    props.value.address.name === "Pauze"
      ? true
      : false;
  const ETA =
    props.value.address.name === "Start Locatie"
      ? Math.round(
          -(props.shiftTimerStarted ? props.time - props.shiftStart : 0)
        ) / 60
      : Math.round(
          (props.value.arrivalTime -
            props.initialPickups[0].departureTime -
            (props.shiftTimerStarted // if shift timeer has been started we also deduce the time that passes since the shift start
              ? props.time - props.shiftStart
              : 0)) /
            60
        );

  const timewindowEnd = props.value.timewindow
    ? convertTimeToSecs(props.value.timewindow.split("-")[1])
    : convertTimeToSecs("23:59"); // this is for starting,and ending locations that dont have timewindows so we set it to 23:59 ending timewindow

  // pickup has warning tag if has a timewindow end other than '23:59'
  const isWarning = timewindowEnd !== convertTimeToSecs("23:59");

  // pickup has a danger tag if its timewindow is expiring in less than 30 mins, but only in case it has a timewindow other than '00:00-23:59'
  const isDanger = isWarning ? timewindowEnd - props.time <= 60 * 30 : false;

  // if it is extra we want to add extra label
  const isExtra = props.value.extraPickup;

  // if pickup is distribution we use slightly different options for completing, discrding, and comments
  const isDistribution = props.value.distributionPickup;

  return (
    <>
      <li
        className={`pickup ${alternative ? "pickup--alternative" : ""} ${
          actualIndex !== 0 ? "pickup--secondary" : ""
        } ${isGarage ? "pickup--garage" : ""}
        ${isDanger ? "pickup--danger" : isWarning ? "pickup--warning" : ""}
        ${isExtra ? "pickup--extra" : ""}
        `}
      >
        <div className="pickup__left-column">
          <ul className="pickup__label-list">
            {alternative ? (
              <li className="pickup__label-list-item">
                <span className="pickup__alt-label">Alternatief</span>
              </li>
            ) : null}
            {isGarage ? (
              <li className="pickup__label-list-item">
                <span className="pickup__alt-label pickup__alt-label--garage" />
              </li>
            ) : null}
            {isExtra ? (
              <li className="pickup__label-list-item">
                <span className="pickup__alt-label pickup__alt-label--extra">
                  Extra
                </span>
              </li>
            ) : null}
          </ul>
          <div className="pickup__left-column-container">
            <div className="pickup__first-row">
              <div className="pickup__ETA-container">
                <div
                  className={`pickup__ETA ${
                    ETA < 0 ? "pickup__ETA--negative" : ""
                  } ${isExtra ? "pickup__ETA--extra" : ""}`}
                >
                  {!isExtra && (
                    <div className="pickup__ETA--values">
                      <span className="pickup__ETA--number">{ETA} </span>
                      <span className="pickup__ETA--mins">min(s)</span>
                    </div>
                  )}
                </div>
              </div>

              <div className="pickup__address-container">
                <p className="pickup__name">
                  {props.value.address.name} - {distanceInKm} km
                </p>
                {props.value.timewindow && (
                  <p className="pickup__timewindow">
                    {" "}
                    <FontAwesomeIcon
                      className="pickup__icon"
                      icon={["far", "clock"]}
                    />
                    {props.value.timewindow}{" "}
                  </p>
                )}

                <p className="pickup__street"> {props.value.address.street}</p>
              </div>
            </div>
            <div className="pickup__second-row">
              {props.windowWidth < 900 ? (
                <Link
                  className="pickup__link pickup__link--gmaps-btn"
                  to={`g-maps/${props.value.address.lat},${props.value.address.lng}`}
                >
                 
                  <FontAwesomeIcon
                    className="pickup__link--icon"
                    icon="directions"
                  />
                  Route
                </Link>
              ) : (
                <button
                  type="button"
                  className="pickup__link pickup__link--gmaps-btn"
                  onClick={() =>
                    props.handleGmapsLandMode(
                      props.value.address.lat,
                      props.value.address.lng
                    )
                  }
                >
                  <FontAwesomeIcon
                    className="pickup__link--icon"
                    icon="directions"
                  />
                  Route
                </button>
              )}
              <span className="pickup__link--divider" />

              {/* Depending on which button user clicks, handleDialogAssignment handles which dialog will pop up depending on button name attribute. */}
              {!isGarage && (
                <button
                  className="pickup__link pickup__link--note-btn"
                  name="note"
                  onClick={(e) => props.handleDialogAssignment(props.index, e)}
                >
                  <FontAwesomeIcon
                    className="pickup__link--icon"
                    icon={["far", "file-alt"]}
                  />
                  Opmerking
                </button>
              )}

              {(props.value.address.pickupDetails ||
                props.value.backOfficeMessage) && (
                <>
                  <span className="pickup__link--divider" />
                  <button
                    className="pickup__link pickup__link--details-btn"
                    name="info"
                    onClick={() => setShowDetails(true)}
                  >
                    <FontAwesomeIcon
                      className="pickup__link--icon"
                      icon={"info-circle"}
                    />
                    Details
                  </button>
                </>
              )}

              {isDistribution && props.value.address.phone && (
                <>
                  <span className="pickup__link--divider" />
                  <button
                    className="pickup__link pickup__link--phone-btn"
                    name="phone"
                    onClick={() => setShowPhone(true)}
                  >
                    <FontAwesomeIcon
                      className="pickup__link--icon"
                      icon={"phone-alt"}
                    />
                    Telefoon
                  </button>
                </>
              )}
            </div>
          </div>
        </div>

        <div
          className={`pickup__right-column ${
            isGarage ? "pickup__right-column--garage" : ""
          }`}
        >
          <button
            className={`pickup__btn pickup__btn--complete ${
              isGarage ? "pickup__btn--complete-garage" : ""
            }`}
            name="accept"
            onClick={
              isGarage
                ? (e) =>
                    props.handleCompleteAndDiscard(props.index, e, "completed")
                : isDistribution
                ? () => setShowDistributionPicker(true) // then check if it is a distribution item
                : () => setShowContainers(true)
            }
          >
            <FontAwesomeIcon className="pickup__btn--icon" icon="check" />
          </button>
          {isGarage ? null : actualIndex === 0 ? (
            <button
              className="pickup__btn pickup__btn--postpone"
              name="postpone"
              onClick={(e) =>
                props.handleCompleteAndDiscard(props.index, e, "postponed")
              }
            >
              <FontAwesomeIcon
                className="pickup__btn--icon pickup__btn--icon-postpone"
                icon="clock"
              />{" "}
            </button>
          ) : null}
          {isGarage ? null : (
            <button
              className="pickup__btn pickup__btn--discard"
              name="discard"
              onClick={(e) => props.handleDialogAssignment(props.index, e)}
            >
              <FontAwesomeIcon className="pickup__btn--icon" icon="times" />{" "}
            </button>
          )}
        </div>
      </li>
      {showContainers && (
        <ContainerPicker
          initialPickups={props.initialPickups}
          selectedPickup={props.value}
          handleClose={() => setShowContainers(false)}
          handleCompleteAndDiscard={props.handleCompleteAndDiscard}
          pickupIndex={props.index}
          driverDetails={props.driverDetails}
        />
      )}
      {showDistributionPicker && (
        <DistribItemsPicker
          initialPickups={props.initialPickups}
          selectedPickup={props.value}
          pickupIndex={props.index}
          handleClose={() => setShowDistributionPicker(false)}
          handleCompleteAndDiscard={props.handleCompleteAndDiscard}
          generalInfo={props.generalInfo}
          driverDetails={props.driverDetails}
        />
      )}
      {showDetails && (
        <Dialog
          handleClose={() => setShowDetails(false)}
          title="Details voor "
          subtitle={props.value.address.name}
        >
          {props.value.address.pickupDetails && (
            <p className={"paragraph"}> {props.value.address.pickupDetails}</p>
          )}

          {props.value.backOfficeMessage && (
            <p className={"paragraph u-margin-top-small"}>
              {" "}
              <strong>Backoffice bericht:</strong>{" "}
              {props.value.backOfficeMessage}
            </p>
          )}
          <button
            className="btn-dialog btn-dialog--decline"
            type="button"
            onClick={() => setShowDetails(false)}
          >
            Sluit
          </button>
        </Dialog>
      )}
      {showPhone && (
        <Dialog
          handleClose={() => setShowPhone(false)}
          title="Telefoon voor "
          subtitle={props.value.address.name}
        >
          <p className={"paragraph"}> {props.value.address.phone}</p>

          <button
            className="btn-dialog btn-dialog--decline"
            type="button"
            onClick={() => setShowPhone(false)}
          >
            Sluit
          </button>
        </Dialog>
      )}
    </>
  );
};

export default withTimeStore(PendingListItem);
