import React from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "underscore";

import * as ROUTES from "../../constants/routes";
import { debounce } from "underscore";

//IMAGES
import logowhite from "../../assets/img/logo-white.svg";

//Icons component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logout from "../Logout";

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevScrollPos: window.pageYOffset,
      isNavVisible: true,
    };
  }

  // LIFECYCLE METHODS //
  componentDidMount() {
    // if window width is smaller than 900px and location is pending or completed, we add the listener
    // if (
    //   this.props.windowWidth < 900 &&
    //   (this.props.location.pathname === "/pending" ||
    //     this.props.location.pathname === "/completed")
    // ) {
    //   window.addEventListener("scroll", this.handleScroll);
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    // if (
    //   // same thing as above
    //   this.props.windowWidth < 900 &&
    //   (this.props.location.pathname === "/pending" ||
    //     this.props.location.pathname === "/completed")
    // ) {
    //   window.addEventListener("scroll", this.handleScroll);
    // } else {
    //   // if not, then we remove listener
    //   window.removeEventListener("scroll", this.handleScroll);
    // }
    // if (
    //   // whenever our width or pathname changes, then always show nav bar again
    //   prevProps.windowWidth !== this.props.windowWidth ||
    //   prevProps.location.pathname !== this.props.location.pathname
    // ) {
    //   this.props.keepTrackOfNavVisibility(true);
    //   this.setState({ isNavVisible: true });
    // }
  }

  componentWillUnmount() {
    // window.removeEventListener("scroll", this.handleScroll);
  }

  // HELPER METHODS //
  handleScroll = debounce(
    (e) => {
      // Get scroll height for differnet devices
      let scrollHeight = Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.body.clientHeight,
        document.documentElement.clientHeight
      );

      // FIXES for iphones which are able to scroll past top and bottom of page //
      // if we scroll past top of page, then always show nav
      if (window.pageYOffset <= 0) {
        if (!this.state.isNavVisible) {
          this.setState({ isNavVisible: true });
        }
        return;
      }

      // if we scroll past bottom of page, then always hide nav
      if (window.innerHeight + window.pageYOffset >= scrollHeight) {
        if (this.state.isNavVisible) {
          this.setState({ isNavVisible: false });
        }
        return;
      }

      const { prevScrollPos, isNavVisible } = this.state;
      const curScrollPos = window.pageYOffset;
      const isNavVisibleAfterLastScroll = prevScrollPos > curScrollPos;

      if (isNavVisible !== isNavVisibleAfterLastScroll) {
        this.props.keepTrackOfNavVisibility(isNavVisibleAfterLastScroll);
        this.setState({
          isNavVisible: isNavVisibleAfterLastScroll,
          prevScrollPos: curScrollPos,
        });
      } else {
        this.setState({ prevScrollPos: curScrollPos });
      }
    },
    10,
    true
  );

  render() {
    const activeRoute = this.props.location.pathname;
    const setActive = (route) =>
      activeRoute === route ? "navigation__link--active" : "";

    console.log(
      "!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!dsfahlaksjdhfjaklsdfhakljsdhf!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"
    );

    return (
      <header>
        <div className={`header`}>
          <img src={logowhite} alt="dispatchX logo" className="header__logo" />
          {this.props.firebase.IN_PRODUCTION ? null : <p className="navigation__dev-logo">DEVELOPMENT MODE</p>}
          <Logout />
        </div>
        <div className={`navigation `}>
          <ul className="navigation__list">
            <li className="navigation__item">
              {/* Link component of React Router enables navigation to different routes. */}
              <Link
                className={`navigation__link ${setActive("/pending")}`}
                to={ROUTES.PENDING}
              >
                <FontAwesomeIcon className="navigation__icon" icon="truck" />
                Te bezoeken
              </Link>
            </li>

            <li className="navigation__item">
              <Link
                className={`navigation__link ${setActive("/completed")}`}
                to={ROUTES.COMPLETED}
              >
                <FontAwesomeIcon
                  className="navigation__icon"
                  icon="check-double"
                />
                Voltooid
              </Link>
            </li>
            <li className="navigation__item">
              <Link
                className={`navigation__link ${setActive("/route-planner")}`}
                to={ROUTES.ROUTE_PLANNER}
              >
                <FontAwesomeIcon
                  className="navigation__icon"
                  icon="map-marked-alt"
                />
                Reisplan
              </Link>
            </li>
            {/* <li className="navigation__item">
							<Link className={`navigation__link ${setActive('/messages')}`} to={ROUTES.MESSAGES}>
								<FontAwesomeIcon className="navigation__icon" icon={[ 'far', 'comment-alt' ]} />
								Messages
							</Link>
						</li> */}
            <li className="navigation__item">
              <Link
                className={`navigation__link ${setActive("/settings")}`}
                to={ROUTES.SETTINGS}
              >
                <FontAwesomeIcon className="navigation__icon" icon="cog" />
                Instellingen
              </Link>
            </li>
          </ul>
        </div>
      </header>
    );
  }
}
export default withRouter(Navigation);
