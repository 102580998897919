//Importing font-awesome library and icons.
import { config } from "@fortawesome/fontawesome-svg-core";

import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faEnvelope,
  faUnlockAlt,
  faCheck,
  faCheckDouble,
  faCheckCircle,
  faTimes,
  faExternalLinkAlt,
  faMapMarkerAlt,
  faTruck,
  faCog,
  faDirections,
  faCircleNotch,
  faUser,
  faPhoneAlt,
  faUserLock,
  faUserClock,
  faGlobe,
  faLock,
  faChevronRight,
  faExclamationCircle,
  faClock,
  faFlagCheckered,
  faSearch,
  faHistory,
  faDumpster,
  faRoad,
  faDesktop,
  faToolbox,
  faDumpsterFire,
  faTasks,
  faCommentAlt,
  faTrashAlt,
  faRoute,
  faInfoCircle,
  faWeightHanging,
  faTrash,
  faUserSlash,
  faWrench,
  faBell,
  faMinusCircle,
  faMapMarkedAlt,
  faCartPlus,
} from "@fortawesome/free-solid-svg-icons";

import {
  faCommentAlt as commentRegular,
  faFileAlt,
  faComments,
  faClock as clockRegular,
} from "@fortawesome/free-regular-svg-icons";
// configuring to not add inline css
config.autoAddCss = false;

library.add(
  faEnvelope,
  faUnlockAlt,
  faTruck,
  faCheck,
  faCheckDouble,
  faCheckCircle,
  commentRegular,
  faCog,
  faTimes,
  faExternalLinkAlt,
  faMapMarkerAlt,
  faDirections,
  faFileAlt,
  faClock,
  faComments,
  faCircleNotch,
  faUser,
  faPhoneAlt,
  faUserLock,
  faUserClock,
  faGlobe,
  faLock,
  faChevronRight,
  faExclamationCircle,
  faFlagCheckered,
  faSearch,
  faHistory,
  clockRegular,
  faDumpster,
  faRoad,
  faDesktop,
  faToolbox,
  faDumpsterFire,
  faTasks,
  faCommentAlt,
  faTrashAlt,
  faRoute,
  faInfoCircle,
  faWeightHanging,
  faTrash,
  faUserSlash,
  faWrench,
  faBell,
  faMinusCircle,
  faMapMarkedAlt,
  faCartPlus
);

export default library;
