// import Observer from 'fontfaceobserver';
// import { css } from 'store-css';
import WebFont from "webfontloader";

function lazyloadFonts() {
  // Import fonts stylesheet
  // css({
  // 	url: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap',
  // 	crossorigin: 'anonymous'
  // });

  WebFont.load({
    google: {
      families: ["Montserrat:400,600,700"],
    },
  });

  //Observe body font
  // const bodyFont = new Observer('Montserrat', {
  // 	weight: 400
  // });

  // bodyFont.load().then(() => {
  // 	document.documentElement.classList.add('montserrat-ready');
  // });
}

export default lazyloadFonts;
