
export const convertTimeToSecs = (time) => {
    const timeArray = time.split(":");
  
    return timeArray.length === 3
      ? timeArray.reduce((acc, time) => {
          return 60 * acc + Number(time);
        })
      : timeArray.reduce((acc, time) => {
          return 60 * (60 * acc + Number(time));
        });
  };