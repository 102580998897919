import * as React from "react";
import Dialog from "../Dialog";
import Swal from "../Swal";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { withFirebase } from "../Firebase";
import { InputField } from "../InputField";
import { isWithinRadius } from "../Pending/Helpers";
import { withCurrentPosition } from "../Geolocation";
import SwalNotification from "../Swal";

const initialAlertStatus = {
  showAlert: false,
  alertMessage: ``,
  alertError: ``,
  alertStatus: `"Failure"`,
};

const DistrItemsPicker = withFirebase(
  ({
    initialPickups,
    selectedPickup,
    pickupIndex,
    handleClose,
    handleCompleteAndDiscard,
    firebase,
    generalInfo,
    driverDetails,
    curPos,
  }) => {
    const [firestoreWriteError, setFirestoreWriteError] = React.useState(null);
    const [driverInput, setDriverInput] = React.useState("");
    const [selectedItemsBackToInventory, setSelectedItemsBackToInventory] = React.useState([])
    const [alert, setAlert] = React.useState(initialAlertStatus);

    let allPickups;

    

    //construct a list that contains all the distribution pickups/collection for this address
    if (selectedPickup.associatedPickups) { //.associatedPickups contains indices of all items that should be grouped with the clicked pickup
      allPickups = selectedPickup.associatedPickups.reduce(
        (acc, pickupIndex) => {
          const associatedPickup = initialPickups.find(
            (pickup) => pickup.index === pickupIndex
          );
          return [...acc, associatedPickup];
        },
        //we start with the clicked pickup and add a field which indicates if items go back to inventory (if collection=true)
        [selectedPickup]
      );
    } else {
      allPickups = [selectedPickup];
    }

    //construct an array of all classes in this distribution item (container collectie, winkelwagen distributie, ...)
    const distrClasses = allPickups.reduce((acc, pickup) => {
      if (!acc.includes(pickup.class)) {
        return [...acc, pickup.class];
      }
      return acc;
    }, []);

    //UI element
    const ItemsContainer = ({ title, items, index }) => {
      return (
        <div className={"container-picker__container"}>
          <Row>
            <Col>
              <h1 className={"container-picker__header"}>{title}</h1>
            </Col>
            <Col className={'col-align-right'}>
              {index === 0 ? <h3 className={"container-picker__header float-right"}>Terug in voorraad?</h3> : ''}
            </Col>
          </Row>
          <List items={items} />
        </div>
      );
    };
    
    //UI Element
    const List = ({ items }) => (
      <div className={"container-picker__list"}>
        {items.map((item) => (
          <Item key={item.distrPickupId} item={item} />
        ))}
      </div>
    );
    
    //UI Element
    const Item = ({ item }) => (
      <Row className={"container-picker__list-item"}>
        <Col>
          {item.address.container} x {item.address.containerCount}
        </Col>
        <Col className={'col-align-right'}>
        {item.collection ? <input
          className={"container-picker__options-checkbox"}
          type="checkbox"
          checked={selectedItemsBackToInventory.includes(item.distrPickupId)}
          id={item.distrPickupId}
          name={item.distrPickupId}
          onChange={(event) => handleBackToInventoryChoice(event)}
        /> : ''}
        </Col>
      </Row>
    );
    
    //when checking the checkboxes that indicate if items need to go back to inventory
    const handleBackToInventoryChoice = (event) => {
      let isChecked = event.target.checked

      //add selected items to selectedItemsArray
      if(isChecked){
        setSelectedItemsBackToInventory([...selectedItemsBackToInventory, event.target.id])
      
      //remove unchecked item from selected items
      }else{
        setSelectedItemsBackToInventory(
          selectedItemsBackToInventory.filter((id) => {return id !== event.target.id })
        )
      }
    }

    //when clicking the save button
    const handleComplete = async (e) => {
      const withinRadius = isWithinRadius(selectedPickup, curPos);

      if (!withinRadius) {
        setAlert({
          showAlert: true,
          alertMessage: `Kan niet voltooien ${selectedPickup.address.name}!`,
          alertError:
            "Je moet binnen 200 meter van de locatie zijn om het te voltooien.",
          alertStatus: "Failure",
        });
        return;
      }

      console.log("press complete");
      // Prevents React from resetting its properties, as we need to use e as an arg in handleCompleteAndDiscard
      e.persist();

      try {
        // set all the items included in pickup as completed in firestore distribution collection
        const distributionItemsProms = allPickups.map((doc) =>
          firebase.updateDistribution(doc.distrPickupId, {
            completed: true,
            time: firebase.timeStamp.now(),
            backToInventory: selectedItemsBackToInventory.includes(doc.distrPickupId)
          })
        );

        // also write notification to be read in clientx clients notification page
        const completedNotificationType =
          generalInfo.dispatchxDistributionNotifications.find(
            (type) => type.class === "distributie levering"
          );

        // instead of writing notifications for each associated pickup,
        // write one notification for one pickup of the bunch
        // and include all items delivered/ picked up in the description field
        const description = `
        <strong>Contactpersoon:</strong><br>${driverInput ? driverInput : "geen"}<br><br><strong>Items:</strong><br>
        ${allPickups
          .map(
            (pickup) =>
              `<strong>${pickup.class}</strong>: ${pickup.address.containerCount} x ${pickup.address.container} ${pickup.collection ? selectedItemsBackToInventory.includes(pickup.distrPickupId) ? '(Terug in voorraad: ja)' : '(Terug in voorraad: nee)' : ''}`
          )
          .join("<br>")}
        `;

        const notificationProm = firebase.addNotification({
          app: "dispatchx",
          clientId: selectedPickup.address.userId,
          contractId: selectedPickup.id,
          distrPickupId: selectedPickup.distrPickupId,
          clientName: selectedPickup.address.name,
          street: selectedPickup.address.street,
          garbageType: selectedPickup.address.garbageType,
          description: description,
          createdBy: driverDetails.name,
          created: firebase.timeStamp.now(),
          class: completedNotificationType.class,
          type: completedNotificationType.type,
          resolvable: completedNotificationType.resolvable,
          resolved: true,
          accepted: true,
          canResolve: completedNotificationType.canResolve,
          photo: null,
        });

        await Promise.all([...distributionItemsProms, notificationProm]);

        handleCompleteAndDiscard(pickupIndex, e, "completed");
      } catch (error) {
        setFirestoreWriteError(error);
      }
    };

    const { showAlert, alertError, alertMessage, alertStatus } = alert;

    return (
      <>
        <Dialog title={"Artikelen"} wide={true} handleClose={handleClose}>
          <div className={"distribution-picker"}>
            {distrClasses.map((distrClass, index) => (
              <ItemsContainer
                index={index}
                key={distrClass}
                title={distrClass.charAt(0).toUpperCase() + distrClass.slice(1)}
                items={allPickups.filter(
                  (pickup) => pickup.class === distrClass
                )}
              />
            ))}
          </div>
          <InputField
            className="distribution-picker__input"
            name="contactPersonDistribution"
            value={driverInput}
            setValue={(e) => setDriverInput(e.target.value)}
            label="Contactpersoon (Optioneel)"
            labelClassName="distribution-picker__input-label"
            containerClassName="distribution-picker__input-container"
          />
          <button
            className={"btn-dialog"}
            name="accept"
            type="button"
            onClick={handleComplete}
          >
            Opslaan
          </button>
        </Dialog>
        {firestoreWriteError && (
          <Swal
            message={"Fout bij het opslaan van distributie artikelen"}
            alertStatus={"Failure"}
            error={`${firestoreWriteError}. Laad de pagina opnieuw en probeer het opnieuw.`}
            closeAlert={() => setFirestoreWriteError(null)}
          />
        )}

        {showAlert && (
          <SwalNotification
            message={alertMessage}
            alertStatus={alertStatus}
            error={alertError}
            closeAlert={() => setAlert(initialAlertStatus)}
          />
        )}
      </>
    );
  }
);

export default withCurrentPosition(DistrItemsPicker);
