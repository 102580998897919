import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Alert extends Component {
  constructor(props) {
    super(props);
    this.timeoutId = null;
  }

  componentDidMount() {
    if (this.props.alertStatus === "Success") {
      this.timeoutId = this.props.hideAlert();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.message !== this.props.message) {
      if (this.props.alertStatus === "Success") {
        //If there is a timeout already with success message, and we get another one,
        //we clear the first one to allow the second one to run.
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
        this.timeoutId = this.props.hideAlert();
      } else {
		// if it's a failure alert, cancel timeout alltogether
		clearTimeout(this.timeoutId);
		this.timeoutId = null;
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
    this.timeoutId = null;
  }

  render() {
    const { error, message, closeAlert, logout, alertStatus } = this.props;
    return (
      //css rules are borrowed from dialog component.
      <div className={`dialog ${!error ? "dialog--green" : "dialog--red"}`}>
        {/* if there is an error property it means we are handling an error alert */}
        {!error ? (
          <FontAwesomeIcon
            className="dialog__icon dialog__icon--alert"
            icon="check"
          />
        ) : (
          <FontAwesomeIcon
            className="dialog__icon dialog__icon--alert"
            icon="exclamation-circle"
          />
        )}

        <p className="dialog__header">{message}</p>
        {error && <span className="dialog__message">{error}</span>}
        {alertStatus !== "Success" && (
          <button className="dialog__btn" onClick={closeAlert}>
            <FontAwesomeIcon
              className="dialog__icon dialog__icon--alert-error"
              icon="times"
            />
          </button>
        )}
        {logout && <button onClick={logout}>Log nu in</button>}
      </div>
    );
  }
}

export default Alert;
