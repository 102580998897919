import React from "react";
import PickupStoreContext from "./context";

const withPickupStore = (Component) => (props) => (
  <PickupStoreContext.Consumer>
    {([pickupState, dispatchPickupState]) => (
      <Component
        pickupState={pickupState}
        dispatchPickupState={dispatchPickupState}
        {...props}
      />
    )}
  </PickupStoreContext.Consumer>
);

export default withPickupStore;
