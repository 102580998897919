import React from "react";

//Calling React's Context API in order to use one Firebase instance
//at the top-level of the component hierarchy in App component.
const FirebaseContext = React.createContext(null);

//Creating higher-order component to be used to pass the firebase instance into a component.
const withFirebase = (Component) => (props) => (
  <FirebaseContext.Consumer>
    {(firebase) => <Component {...props} firebase={firebase} />}
  </FirebaseContext.Consumer>
);

export { withFirebase };

export default FirebaseContext;
