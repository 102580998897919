import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dialog from "../Dialog";

const ExtrasNotifications = ({ extras, removed, setExtras, setRemoved }) => {
  const clearNotifications = () => {
    setExtras([]);
    setRemoved([]);
  };
  console.log([extras, removed], 'HUH');
  return (
    <ul className="pickup-notifier__extras">
      {[...extras, ...removed]
        .sort((a, b) => b.created.seconds - a.created.seconds)
        .map((item) => {
          const timeCreated = item.created.toDate();
          return item.extraPickup && !item.backOfficeProcessed ? (
            <ExtraNotification
              key={`${item.address.name}-${item.created.seconds}`}
              item={item}
              timeCreated={timeCreated}
            />
          ) : (
            <RemovedNotification
              key={`${item.address.name}-${item.created.seconds}`}
              item={item}
              timeCreated={timeCreated}
            />
          );
        })}

      {/* {removed
        .sort((a, b) => b.created.seconds - a.created.seconds)
        .map((item) => {
          const timeCreated = item.created.toDate();
          return <RemovedNotification item={item} timeCreated={timeCreated} />;
        })} */}

      <button className="btn-notifications" onClick={clearNotifications}>
        Ok
      </button>
    </ul>
  );
};

const ExtraNotification = ({ item, timeCreated }) => {
  const [showBackOfficeMsg, setShowBackOfficeMsg] = useState(false);

  return (
    <>
      <li className="pickup-notifier__notification pickup-notifier__notification--extra">
        <FontAwesomeIcon
          icon={"cart-plus"}
          className="pickup-notifier__notification-icon pickup-notifier__notification-icon--extra"
        />{" "}
        <p className="pickup-notifier__notification-text">
          {item.address.name} toegevoegd!{" "}
        </p>
        <div className="pickup-notifier__notification-details">
          {item.backOfficeMessage && (
            <p
              className="pickup-notifier__notification-back-office-msg"
              onClick={() => setShowBackOfficeMsg(true)}
            >
              <FontAwesomeIcon
                icon={"envelope"}
                className="pickup-notifier__notification-icon pickup-notifier__notification-icon--message"
              />{" "}
              Zie bericht
            </p>
          )}
          <p className="pickup-notifier__notification-time">{`${pad(
            timeCreated.getHours()
          )}:${pad(timeCreated.getMinutes())}`}</p>
        </div>
      </li>
      {showBackOfficeMsg && (
        <Dialog
          handleClose={() => setShowBackOfficeMsg(false)}
          title="Backoffice bericht voor "
          subtitle={item.address.name}
        >
          <p
            className={
              "paragraph pickup-notifier__notification-back-office-msg-text"
            }
          >
            {" "}
            {item.backOfficeMessage}
          </p>
          <button
            className="btn-dialog btn-dialog--decline"
            type="button"
            onClick={() => setShowBackOfficeMsg(false)}
          >
            Sluit
          </button>
        </Dialog>
      )}
    </>
  );
};

const RemovedNotification = ({ item, timeCreated }) => (
  <li className="pickup-notifier__notification pickup-notifier__notification--removed">
    <FontAwesomeIcon
      icon={"times"}
      className="pickup-notifier__notification-icon pickup-notifier__notification-icon--removed"
    />{" "}
    <p className="pickup-notifier__notification-text">
      {item.address.name} verwijderd!
    </p>
    <p className="pickup-notifier__notification-time">{`${pad(
      timeCreated.getHours()
    )}:${pad(timeCreated.getMinutes())}`}</p>
  </li>
);

const pad = (num) => {
  return ("0" + num).slice(-2);
};

export default ExtrasNotifications;
