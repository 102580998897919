import React from "react";
import ReactDOM from "react-dom";

import "@fortawesome/fontawesome-svg-core/styles.css";
import "./css/style.css";
import "./css/grid.css";

import App from "./components/App";
import Firebase, { FirebaseContext } from "./components/Firebase";

const softwareVersion = "1.4.1";

ReactDOM.render(
  //Using React's Context API to provide a Firebase instance
  //once at the top-level of the component hierarchy. Any component within can now consume the instance.
  <FirebaseContext.Provider value={new Firebase()}>
    <App softwareVersion={softwareVersion} />
  </FirebaseContext.Provider>,
  document.getElementById("root")
);
