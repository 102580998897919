import React from "react";
import { getDistance } from "geolib";

import PendingLandModeList from "./PendingLandModeList";

class PendingLandMode extends React.Component {
  constructor(props) {
    super(props);
    this.state = { searchInput: "" };
  }

  handleSearchInput = (e) => {
    this.setState({
      searchInput: e.target.value,
    });
  };

  render() {
    const { searchInput } = this.state;
    const {
      pending,
      currentPosition,
      handleDialogAssignment,
      handleCompleteAndDiscard,
      handleGmapsLandMode,
    } = this.props;

    //We want to remove the alterbative pickup from our array before sorting from closest to furthest

    const pendingPickupsNoAlt = pending.filter(
      (pickup) => pickup.alternative !== true
    );

    //We want to sort our array from closest to furthest
    const { latitude, longitude } = currentPosition;

    const pendingPickupsWithDistProp = pendingPickupsNoAlt.map((pickup) => {
      const distanceFromTarget = getDistance(
        { latitude, longitude },
        {
          latitude: pickup.address.lat,
          longitude: pickup.address.lng,
        }
      );

      pickup.distFromTarget = distanceFromTarget;
      return pickup;
    });

    //Now that are pickup array has a distance property we can sort it accordingly
    pendingPickupsWithDistProp.sort(
      (a, b) => a.distFromTarget - b.distFromTarget
    );

    return (
      // This will be the second component.
      <PendingLandModeList
        initialPickups={this.props.initialPickups}
        pending={pendingPickupsWithDistProp}
        handleDialogAssignment={handleDialogAssignment}
        handleCompleteAndDiscard={handleCompleteAndDiscard}
        handleSearchInput={this.handleSearchInput}
        searchInput={searchInput}
        handleGmapsLandMode={handleGmapsLandMode}
        driverDetails={this.props.driverDetails}
        generalInfo={this.props.generalInfo}

      />
    );
  }
}

export default PendingLandMode;
