import React from "react";
import TimeContext from "./context";

const withTimeContext = (Component) => (props) => (
  <TimeContext.Consumer>
    {(time) => <Component time={time} {...props} />}
  </TimeContext.Consumer>
);

export default withTimeContext;
