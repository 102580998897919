import React from "react";

import PendingLandModeListItem from "./PendingLandModeListItem";
import ListNoResults from "../ListNoResults";
import Search from "../Search";

const PendingLandModeList = (props) => {
  const pendingPickups = props.pending;

  // We show the search input only when there are more than two pickups, so that's only when we need to filter our pickups as well
  if (pendingPickups.length > 2) {
    var pendingPickupsFiltSearch = pendingPickups.filter(
      (pickup) =>
        pickup.address.name
          .toLowerCase()
          .includes(props.searchInput.toLowerCase()) ||
        pickup.address.street
          .toLowerCase()
          .includes(props.searchInput.toLowerCase())
    );
  }

  //If our pickups are less than two then pendingPickupsFiltSearch is undefined, so we need to map the pending pickups instead
  const pendingToShow = pendingPickupsFiltSearch
    ? pendingPickupsFiltSearch
    : pendingPickups;

  // if our array by this point has pickups, we can go ahead and construct our list
  if (pendingToShow.length > 0) {
    var pendingPickupsList = pendingToShow.map((pickup, index) => {
      //We are looping through our pending pickups. If pickup object is NOT completed and NOT discarded we add it to our list.
      //Each pickup is an object with a key starting at '1' and an object containing pickup details as a value.
      //As such, we start at index + 1, as we discarded the first one in componentDidMount.

      return (
        <PendingLandModeListItem
          key={`${pickup.address.street}-${pickup.address.name}${
            pickup.extraPickup ? `-extra-${pickup.created.seconds}` : ""
          }`}
          initialPickups={props.initialPickups}
          value={pickup}
          index={pickup.index}
          handleDialogAssignment={props.handleDialogAssignment}
          handleCompleteAndDiscard={props.handleCompleteAndDiscard}
          handleGmapsLandMode={props.handleGmapsLandMode}
          driverDetails={props.driverDetails}
          generalInfo={props.generalInfo}

        />
      );
    });
  }

  return (
    <div className="pickup__list-container pickup__list-container--closest">
      {/* We show the search input only if there are more than two pickups to show */}
      {pendingPickups.length > 2 && (
        <Search
          input={props.searchInput}
          handleChange={props.handleSearchInput}
        />
      )}
      {/* If search filter has no results, we show no pickup message */}
      {pendingPickupsList ? (
        <ul className="pickup__list pickup__list--closest">
          {pendingPickupsList}
        </ul>
      ) : (
        <ListNoResults message={"No pickups found."} />
      )}
    </div>
  );
};

export default PendingLandModeList;
