import React from 'react';

import lazyloadFonts from './font';

//HOC that receives a condition function as a parameter and decides if it should redirect to a public route, or render the passed component.
const withFontLoader = (Component) => {
	class withFontLoader extends React.Component {
		componentDidMount() {
			//Enable font lazy loading.
			lazyloadFonts();
		}

		render() {
			return (
				//Adding consumer component so that authUser value is available to us even before onAuthChange listener redirects.
				//As such we avoid showing the protected page before the redirect happens.
				<Component  {...this.props} />
			);
		}
	}
	//Using recompose library to wrap 'withAuthorization' HOC with 'withRouter' and 'withFirebase' HOCs
	return withFontLoader;
};

export default withFontLoader;