import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withTimeStore from "../TimeStore/withTimeStore";


const Notifier = ({ modifierClassname, icon, children }) => {
  const [showNotifier, setShowNotifier] = useState(true);

  return (
    <li
      className={`pickup-notifier pickup-notifier--${modifierClassname} ${
        !showNotifier ? "pickup-notifier--closed" : ""
      }`}
      key={modifierClassname}
    
    >
      <FontAwesomeIcon className="pickup-notifier__icon" icon={icon}   onClick={() => setShowNotifier(true)}/>

      {showNotifier && (
        <>
          {children}{" "}
          <FontAwesomeIcon
            className="pickup-notifier__icon pickup-notifier__icon--exit"
            icon={"minus-circle"}
            onClick={() => setShowNotifier(!showNotifier)}
          />
        </>
      )}
    </li>
  );
};

const ExpiringNotifier = ({ pickupsNames, modifierClassname, icon }) => (
  <Notifier modifierClassname={modifierClassname} icon={icon}>
    <p className="pickup-notifier__names ">{pickupsNames} </p>
  </Notifier>
);

const PostponedNotifier = ({ pickupsNames, modifierClassname, icon }) => (
  <Notifier modifierClassname={modifierClassname} icon={icon}>
    <p className="pickup-notifier__names ">{pickupsNames} </p>
  </Notifier>
);

const ProgressNotifier = withTimeStore(
  ({
    pending,
    processed,
    expiring,
    modifierClassname,
    icon,
    rawNoDoubles,
    time,
    postponedPickups,
  }) => {
    // remove extras and alternatives from all categories
    const pendingNoExtras = pending.filter(
      (item) => !item.extraPickup && !item.alternative
    );
    const processedNoExtras = processed.filter((item) => !item.extraPickup);
    // get extras
    const extras = pending.filter(
      (item) => item.extraPickup && !item.alternative
    );
    const totalPickups =
      pendingNoExtras.length +
      processedNoExtras.length +
      postponedPickups.length;
    return (
      <Notifier modifierClassname={modifierClassname} icon={icon}>
        <div className="pickup-notifier__progress-container">
          <Dashboard
            processed={processedNoExtras}
            pending={pendingNoExtras}
            extras={extras}
            expiring={expiring}
          />
          <ProgressBar
            totalPickups={totalPickups}
            processed={processedNoExtras}
            rawNoDoubles={rawNoDoubles}
            className="pickup-notifier__progress-bar"
            time={time}
          />
        </div>
      </Notifier>
    );
  }
);

const Dashboard = ({ processed, pending, extras, expiring }) => {
  const completed = processed.filter((location) => location.completed).length;
  const discarded = processed.filter((location) => location.discarded).length;
  const pendingNumber = pending.length;
  const expiringNumber = expiring.length;
  const extraPickupsNumber = extras.length;

  return (
    <div className="pickup-notifier__progress-dashboard">
      <span className="pickup-notifier__progress-icon-container pickup-notifier__progress-icon-container--completed">
        <FontAwesomeIcon icon={"check"} />
        <p className="pickup-notifier__progress-amounts">{completed}</p>
      </span>
      <span className="pickup-notifier__progress-icon-container pickup-notifier__progress-icon-container--discarded">
        <FontAwesomeIcon icon={"times"} />
        <p className="pickup-notifier__progress-amounts">{discarded}</p>
      </span>
      <span className="pickup-notifier__progress-icon-container pickup-notifier__progress-icon-container--pending">
        <FontAwesomeIcon icon={"truck"} />
        <p className="pickup-notifier__progress-amounts"> {pendingNumber}</p>
      </span>
      <span className="pickup-notifier__progress-icon-container pickup-notifier__progress-icon-container--expiring">
        <FontAwesomeIcon icon={"bell"} />
        <p className="pickup-notifier__progress-amounts">{expiringNumber}</p>
      </span>
      <span className="pickup-notifier__progress-icon-container pickup-notifier__progress-icon-container--extra">
        <FontAwesomeIcon icon={"cart-plus"} />
        <p className="pickup-notifier__progress-amounts">
          {extraPickupsNumber}
        </p>
      </span>
    </div>
  );
};

const ProgressBar = ({
  totalPickups,
  processed,
  className,
  time,
  rawNoDoubles,
}) => {
  const completed = processed.filter((location) => location.completed).length;
  const discarded = processed.filter((location) => location.discarded).length;
  const processedCompleted = Math.round((completed / totalPickups) * 100);
  const processedDiscarded = Math.round((discarded / totalPickups) * 100);

  const completedRef = useRef(null);
  const discardedRef = useRef(null);
  const expectationRef = useRef(null);

  useEffect(() => {
    completedRef.current.style.width = `${processedCompleted}%`;
    discardedRef.current.style.left = `${processedCompleted}%`;
    discardedRef.current.style.width = `${processedDiscarded}%`;
    expectationRef.current.style.left = getWidthExpectationLine();
  });

  // find the where the driver should be in progress by the current time

  const getWidthExpectationLine = () => {
    // we need the index from rawNoDOubles, because the index currently in locations does not take into account lcoations wtith more than one contracts
    const pickupDriverShouldBeAt = rawNoDoubles.reduce(
      (acc, location, index) => {
        // both values are in seconds
        if (
          Math.abs(location.arrivalTime - time) ===
          Math.abs(acc.arrivalTime - time)
        ) {
          // if both timewindows are equally away from current time select the one with the positive difference
          if (location.arrivalTime > acc.arrivalTime) {
            return { ...location, index };
          } else {
            return acc;
          }
        } else if (
          Math.abs(location.arrivalTime - time) <
          Math.abs(acc.arrivalTime - time)
        ) {
          return { ...location, index };
        } else {
          return acc;
        }
      }
    );
    console.log(pickupDriverShouldBeAt, "PIKCCUP DRIVER SHOULD BE AT");

    return `${Math.round(
      ((pickupDriverShouldBeAt.index + 1) / (rawNoDoubles.length - 1)) * 100
    )}%`; // - 1 to account for end location which has same times as unload location
  };

  return (
    <div className={className}>
      <div
        className={`${className} ${className}--completed`}
        ref={completedRef}
      />
      <div
        className={`${className} ${className}--discarded`}
        ref={discardedRef}
      />
      <span
        className="pickup-notifier__expectation-line"
        ref={expectationRef}
      />
    </div>
  );
};

export { ExpiringNotifier, PostponedNotifier, ProgressNotifier };
