import React, { useEffect, useState } from "react";
import Dialog from "../Dialog";
import Swal from "../Swal";
import { withFirebase } from "../Firebase";
import { withCurrentPosition } from "../Geolocation";
import { isWithinRadius } from "./Helpers";
import format from "date-fns/format";

const ContainerPicker = withFirebase(
  ({
    initialPickups,
    selectedPickup,
    handleClose,
    firebase,
    handleCompleteAndDiscard,
    pickupIndex,
    driverDetails,
    curPos,
  }) => {
    const [postCalcChoices, setPostCalcChoices] = useState([]);
    const [writeError, setWriteError] = useState(null);
    let allPickups;

    if (selectedPickup.associatedPickups) {
      allPickups = selectedPickup.associatedPickups.reduce(
        (acc, pickupIndex) => {
          const extraPickup = initialPickups.find(
            (pickup) => pickup.index === pickupIndex
          );
          return [...acc, extraPickup];
        },
        [selectedPickup]
      );
    } else {
      allPickups = [selectedPickup];
    }

    console.log({ allPickups });

    const normalPickups = allPickups.filter(
      (pickup) => pickup.address.postCalculation === false
    );

    const postCalcPickups = allPickups.filter(
      (pickup) => pickup.address.postCalculation === true
    );

    const handleSubmit = async (e) => {
      // Prevents React from resetting its properties:
      e.persist();
      console.log("CURRENT TARGET", e.currentTarget);

      const withinRadius = isWithinRadius(selectedPickup, curPos);

      if (postCalcChoices.length > 0 && withinRadius) {
        // make promises
        console.log("CURRENT TARGET", e.currentTarget);

        try {
          const proms = postCalcChoices.map((choice) => {
            const selectedPostCalcPickup = postCalcPickups.find(
              (pickup) => pickup.address.unit === choice.code
            );

            const currentDate = format(new Date(), "y-MM-dd");

            if (selectedPostCalcPickup.extraPickup) {
              return firebase.db.collection("items").add({
                class: "nacalculatie",
                code: choice.code,
                created: firebase.timeStamp.now(),
                postCalculation: true,

                rescheduled: false,
                description: "nacalculatie lediging",
                totalPrice: selectedPostCalcPickup.address.totalPrice,
                normalPrice: selectedPostCalcPickup.address.normalPrice,
                discountPrice: selectedPostCalcPickup.address.discountPrice,
                garbageType: choice.garbageType,
                name: choice.name,
                driverId: firebase.auth.currentUser.uid,
                clientId: selectedPostCalcPickup.address.userId,
                createdBy: driverDetails.name,
                linkedTo: selectedPostCalcPickup.extraPickupId, // this will be used wehn we want to delete the post calc containers, if said pickup is recovered
                pickupDate: currentDate,
                count: 1,
                timeWindow: selectedPostCalcPickup.timewindow,
                contractId: selectedPostCalcPickup.id,
              });
            } else {
              return firebase.db.collection("items").add({
                class: "nacalculatie",
                code: choice.code,
                created: firebase.timeStamp.now(),
                postCalculation: true,

                rescheduled: false,
                description: "nacalculatie lediging",
                totalPrice: selectedPostCalcPickup.address.totalPrice,
                normalPrice: selectedPostCalcPickup.address.normalPrice,
                discountPrice: selectedPostCalcPickup.address.discountPrice,
                garbageType: choice.garbageType,
                name: choice.name,
                driverId: firebase.auth.currentUser.uid,
                clientId: selectedPostCalcPickup.address.userId,
                createdBy: driverDetails.name,
                pickupDate: currentDate,
                count: 1,
                timeWindow: selectedPostCalcPickup.timewindow,
                contractId: selectedPostCalcPickup.id,
              });
            }
          });

          await Promise.all(proms);

          handleCompleteAndDiscard(pickupIndex, e, "completed");
        } catch (error) {
          setWriteError(error);
        }

        // see pending/index.js for method
      } else {
        // handle cases when driver is not within radius,
        // or is completing a post calc location
        // with no units selected at all
        if (!withinRadius) {
          // handle not within radius
          handleCompleteAndDiscard(pickupIndex, e, "completed");
        } else {
          // handle no units selected
          // create as many empty post calc items as
          // there are post calc pickups
          const proms = postCalcPickups.map((pickup) =>
            firebase.db.collection("items").add({
              class: "nacalculatie",
              code: "geen lediging",
              created: firebase.timeStamp.now(),
              postCalculation: true,
              rescheduled: false,
              description: "geen nacalculatie lediging",
              totalPrice: 0,
              normalPrice: 0,
              discountPrice: 0,
              garbageType: "geen afvaltype",
              name: "geen nacalculatie lediging",
              driverId: firebase.auth.currentUser.uid,
              clientId: pickup.address.userId,
              createdBy: driverDetails.name,
              pickupDate: format(new Date(), "y-MM-dd"),
              count: 1,
              timeWindow: pickup.timewindow,
              contractId: pickup.id,
              linkedTo: pickup.extraPickup ? pickup.extraPickupId : null,
            })
          );

          try {
            await Promise.all(proms);
            handleCompleteAndDiscard(pickupIndex, e, "completed");
          } catch (error) {
            setWriteError(error);
          }
        }
      }
    };

    console.log(postCalcChoices, "POST CALC CHOICES");

    return (
      <>
        <Dialog title={"Containers"} handleClose={handleClose}>
          <div className={"container-picker"}>
            {normalPickups.length > 0 && (
              <NormalPickups pickups={normalPickups} firebase={firebase} />
            )}
            {postCalcPickups.length > 0 && (
              <PostCalcPickups
                pickups={postCalcPickups}
                setPostCalcChoices={setPostCalcChoices}
                postCalcChoices={postCalcChoices}
                firebase={firebase}
              />
            )}
          </div>
          <button
            className={"btn-dialog"}
            name="accept"
            type="button"
            onClick={handleSubmit}
          >
            Opslaan
          </button>
        </Dialog>
        {writeError && (
          <Swal
            message={"Fout bij het opslaan van nacalculatiecontainers"}
            alertStatus={"Failure"}
            error={`${writeError}. Laad de pagina opnieuw en probeer het opnieuw.`}
            closeAlert={() => setWriteError(null)}
          />
        )}
      </>
    );
  }
);

const NormalPickups = ({ pickups, firebase }) => {
  console.log(pickups, "PICKUPS IN NORMAL");
  const [normalContainers, setNormalContainers] = useState(null);
  const [errorGettingContainerInfo, setErrorGettingContainerInfo] =
    useState(null);
  // get info about post calc containers
  useEffect(() => {
    const fetchData = async () => {
      //make promises
      try {
        const proms = pickups.map((pickup) =>
          firebase.db
            .collection("pricelist")
            .doc(`${pickup.address.unit}`)
            .get()
            .then((doc) => doc.data())
        );

        const results = await Promise.all(proms);
        setNormalContainers(results);
      } catch (error) {
        setErrorGettingContainerInfo(error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <h1 className={"container-picker__header"}>Normaal</h1>
      {normalContainers ? (
        <ul className={"container-picker__list container-picker__list--normal"}>
          {pickups.map((pickup, index) => (
            <li
              key={pickup.address.unit}
              className={
                "container-picker__list-item container-picker__list-item--normal"
              }
            >
              {normalContainers[index].name} x {pickup.address.containerCount} -{" "}
              {pickup.address.garbageType}
            </li>
          ))}
        </ul>
      ) : (
        "Laden..."
      )}
      {errorGettingContainerInfo && (
        <Swal
          message={"Fout bij ophalen van containers"}
          alertStatus={"Failure"}
          error={`${errorGettingContainerInfo}. Laad de pagina opnieuw en probeer het opnieuw.`}
          closeAlert={() => setErrorGettingContainerInfo(null)}
        />
      )}
    </>
  );
};

const PostCalcPickups = ({
  pickups,
  firebase,
  postCalcChoices,
  setPostCalcChoices,
}) => {
  const [postCalcContainers, setPostCalcContainers] = useState(null);
  const [errorGettingContainerInfo, setErrorGettingContainerInfo] =
    useState(null);
  // get info about post calc containers
  useEffect(() => {
    const fetchData = async () => {
      //make promises
      try {
        const proms = pickups.map((pickup) =>
          firebase.db
            .collection("pricelist")
            .doc(`${pickup.address.unit}`)
            .get()
            .then((doc) => doc.data())
        );

        const results = await Promise.all(proms);
        setPostCalcContainers(results);
      } catch (error) {
        setErrorGettingContainerInfo(error);
      }
    };
    fetchData();
  }, []);

  const handleChoice = (choice, containerIndex) => {
    if (
      postCalcChoices.find(
        (container) =>
          container.name === choice.name && container.index === containerIndex
      )
    ) {
      setPostCalcChoices(
        postCalcChoices.filter(
          (container) =>
            !(
              container.name === choice.name &&
              container.index === containerIndex
            )
        )
      );
    } else {
      setPostCalcChoices(
        postCalcChoices.concat([{ ...choice, index: containerIndex }])
      );
    }
  };

  return (
    <>
      <h1 className={"container-picker__header"}>Nacalculatie</h1>
      {postCalcContainers ? (
        <ul
          className={"container-picker__list container-picker__list--postcalc"}
        >
          {pickups.map((pickup, index) => (
            <li
              key={pickup.address.unit}
              className={
                "container-picker__list-item container-picker__list-item--postcalc"
              }
            >
              {Array(pickup.address.containerCount)
                .fill("")
                .map((containerInstance, indexInnerLoop) => {
                  return (
                    <div
                      key={`${postCalcContainers[index].name}-${indexInnerLoop}`}
                      className={"container-picker__options-container"}
                    >
                      <input
                        className={"container-picker__options-checkbox"}
                        type="checkbox"
                        id={`container${postCalcContainers[index].name}-${indexInnerLoop}`}
                        name={`container${postCalcContainers[index].name}-${indexInnerLoop}`}
                        onChange={() =>
                          handleChoice(
                            postCalcContainers[index],
                            indexInnerLoop
                          )
                        }
                      />
                      <label
                        className={"container-picker__options-label"}
                        htmlFor={`container${postCalcContainers[index].name}-${indexInnerLoop}`}
                      >
                        {postCalcContainers[index].name} -{" "}
                        {pickup.address.garbageType}
                      </label>
                    </div>
                  );
                })}
            </li>
          ))}
        </ul>
      ) : (
        "Laden..."
      )}
      {errorGettingContainerInfo && (
        <Swal
          message={"Fout bij ophalen van containers"}
          alertStatus={"Failure"}
          error={`${errorGettingContainerInfo}. Laad de pagina opnieuw en probeer het opnieuw.`}
          closeAlert={() => setErrorGettingContainerInfo(null)}
        />
      )}
    </>
  );
};

export default withCurrentPosition(ContainerPicker);
