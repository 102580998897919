import React, { useState, useRef, useEffect, useMemo } from "react";
import { withGoogleAPI } from "../GoogleMaps/withGoogleAPI";
import { withCurrentPosition } from "../Geolocation";
import { Map, Marker, InfoWindow, Polyline } from "google-maps-react";
import { compose } from "recompose";
import { isEmpty, isEqual, xorWith } from "lodash";
import withPickupStore from "../PickupStore/withPickupStore";
import ListNoResults from "../ListNoResults";

const chunkArray = (arr, size) =>
  arr.length > size
    ? [arr.slice(0, size), ...chunkArray(arr.slice(size), size)]
    : [arr];

//lodash func to check deep equality for array of objects
const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual));

// hook for accessing previous value of state property
const usePrevious = (value) => {
  const ref = useRef([]);
  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

const containerStyle = { position: "relative", width: "100%", height: "100%" };

const SVGtemplateLocation = `<svg xmlns="http://www.w3.org/2000/svg" width="15.521" height="28.175" viewBox="0 0 15.521 28.175"><g transform="translate(-24.458 -4.051)"><path d="M32.219,4.551a7.25,7.25,0,0,0-6.5,10.487l6.5,15.868,6.5-15.869a7.156,7.156,0,0,0,.435-1.062h0a7.266,7.266,0,0,0-6.933-9.423Zm0,9.683a2.423,2.423,0,1,1,2.422-2.423A2.423,2.423,0,0,1,32.219,14.234Z" fill="{{ color }}" stroke="{{ border }}" stroke-width="0.7"/></g></svg>`;
const TruckIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="29.115" height="22.462" viewBox="0 0 29.115 22.462"><g transform="translate(-125.42 -417.38)"><g transform="translate(52 264)"><path d="M101.865,164.6l-6.354-1.275v-2.982h4.658ZM88.656,156v6.91H87.272V156Zm-3.7,0v6.91H83.575V156Zm-3.7,0v6.91H79.878V156Zm-3.7,0v6.91H76.18V156Zm-4.144,9.663V153.92a.537.537,0,0,1,.54-.54h17.07a.537.537,0,0,1,.541.54v11.747Zm22.781,3.24a3.846,3.846,0,0,0-3.844,3.7h-8.5a3.845,3.845,0,0,0-7.662,0H73.831a.41.41,0,0,1-.411-.409v-5.606H92.032a.461.461,0,0,0,.461-.461V156.088h7.525v3.336H95.05a.461.461,0,0,0-.461.459V163.7a.463.463,0,0,0,.414.46l.013,0,7.251,1.454.269.674V172.2a.407.407,0,0,1-.411.409h-2.081A3.845,3.845,0,0,0,96.2,168.907Zm0,.922a2.916,2.916,0,1,1-2.914,2.916A2.908,2.908,0,0,1,96.2,169.829Zm-16.171.183a2.915,2.915,0,1,1-2.914,2.916A2.911,2.911,0,0,1,80.03,170.012Z" fill="#ec0000" fill-rule="evenodd"/></g></g></svg>`;

const RoutePlanner = ({ google, curPos, windowHeight, pickupState }) => {
  // get previous state for expiring locations
  const prevStateExpiring = usePrevious(pickupState.expiring);
  const prevStatePending = usePrevious(pickupState.pending);
  const prevStateProcessed = usePrevious(pickupState.processed);

  const locationMarkersRef = useRef(null);

  const [activeMarker, setActiveMarker] = useState(null);
  const [activeMarkerName, setActiveMarkerName] = useState(null);
  const [activeMarkerAddress, setActiveMarkerAddress] = useState(null);
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [polylineCoords, setPolylineCoords] = useState([]);

  const { latitude, longitude, accuracy } = curPos;
  const currentPosition = { lat: latitude, lng: longitude };
  const mapContainerRef = useRef(null);

  useEffect(() => {
    mapContainerRef.current.style.height = `calc(${windowHeight}px - 9rem)`;
  }, [windowHeight]);

  const onMarkerClick = (markerProps, marker, e) => {
    setActiveMarker(marker);
    setActiveMarkerName(marker.name);
    setActiveMarkerAddress(markerProps.address);
    setShowInfoWindow(true);
  };

  const onMapClick = (mapProps, map, e) => {
    setShowInfoWindow(false);
  };

  // MARKERS

  const getLocationMarkers = () => {
    // if the values we care about have changed then we compute markres again
    if (
      prevStatePending.length !== pickupState.pending.length ||
      prevStateProcessed.length !== pickupState.processed.length ||
      !isArrayEqual(prevStateExpiring, pickupState.expiring)
    ) {
      // get all locations by merging all pending and processed locations (they are always up to date, also with extra locations coming in)
      const locations = [
        ...pickupState.pending.filter((location) => !location.alternative),
        ...pickupState.processed,
      ];
      console.log(
        "MARKERS DRWNNNNNNNNNNNNNNNNN+++++++++++++++++++++++++++)))))))))"
      );
      console.log(prevStatePending, pickupState.pending, "PENDING");
      console.log(
        prevStatePending.length !== pickupState.pending.length,
        "PENDING"
      );
      console.log(prevStateExpiring, pickupState.expiring, "EXPIRING");
      console.log(
        isArrayEqual(prevStateExpiring, pickupState.expiring),
        "EXPIRING"
      );
      return locations.map((location) => {
        const position = {
          lat: location.address.lat,
          lng: location.address.lng,
        };
        const name = location.address.name;

        //  check if it is part of expiring pickups
        const isExpiring = pickupState.expiring.find(
          (locationExpiring) => locationExpiring.id === location.id
        );

        let icon;
        // make icons according to location status
        if (
          location.id === "start" ||
          location.id === "end" ||
          location.id === "unload"
        ) {
          icon = SVGtemplateLocation.replace("{{ color }}", "#ffffff").replace(
            "{{ border }}",
            "#000"
          );
        } else if (location.completed) {
          icon = SVGtemplateLocation.replace("{{ color }}", "#24d5d8").replace(
            "{{ border }}",
            "#ffffff"
          );
        } else if (location.discarded) {
          icon = SVGtemplateLocation.replace("{{ color }}", "#ff3c4b").replace(
            "{{ border }}",
            "#ffffff"
          );
        } else if (location.extraPickup) {
          icon = SVGtemplateLocation.replace("{{ color }}", "#52b700").replace(
            "{{ border }}",
            "#ffffff"
          );
        } else if (isExpiring) {
          icon = SVGtemplateLocation.replace("{{ color }}", "#d61fe6").replace(
            "{{ border }}",
            "#ffffff"
          );
        } else {
          icon = SVGtemplateLocation.replace("{{ color }}", "#fecd2f").replace(
            "{{ border }}",
            "#ffffff"
          );
        }

        return (
          <Marker
            key={`${location.id}${
              location.extraPickup ? `-extra-${location.created.seconds}` : ""
            }`}
            position={position}
            name={name}
            address={location.address.street}
            animation={isExpiring ? google.maps.Animation.BOUNCE : null}
            onClick={onMarkerClick}
            icon={{
              url:
                "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(icon),
            }}
          />
        );
      });
    } else {
      // if not we simply return the ref value we have saved from the previously rendered markers,
      // whuich wont cause a rerender of the markers as it referencing the same array
      return locationMarkersRef.current;
    }
  };

  const locationMarkers = getLocationMarkers();

  // save current iteration of location markers to ref
  locationMarkersRef.current = locationMarkers;

  const currentPositionMarker = useMemo(
    () => (
      <Marker
        position={currentPosition}
        name={"Huidige positie"}
        onClick={onMarkerClick}
        icon={{
          url:
            "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(TruckIcon),
        }}
      />
    ),
    [curPos]
  );

  // POLYLINE - UNCOMMENT TO USE

  // useEffect(() => {
  //   // potential timeouts for getting directions when OVER QUERY LIMIT
  //   const timeoutIds = [];
  //   // delay factor for setting timeouts on a rate of 1 request per 1 second
  //   let delayFactor = 0;
  //   // get reference to directions API instance
  //   const directionsService = new google.maps.DirectionsService();

  //   // create waypoints from all locations
  //   const waypoints = pickupState.rawPickupsNoDouble.map((location) => {
  //     return {
  //       location: { lat: location.address.lat, lng: location.address.lng },
  //       stopover: true,
  //     };
  //   });

  //   // if waypoints are more than 27 we need to cut them up into pieces and send seperate requests
  //   const clusteredWaypoints = chunkArray(waypoints, 27);

  //   console.log("WAYPOINTS", clusteredWaypoints);

  //   //create requests
  //   const directionsRequests = clusteredWaypoints.map((cluster) => {
  //     return {
  //       origin: {
  //         lat: cluster[0].location.lat,
  //         lng: cluster[0].location.lng,
  //       },
  //       destination: {
  //         lat: cluster[cluster.length - 1].location.lat,
  //         lng: cluster[cluster.length - 1].location.lng,
  //       },
  //       waypoints: cluster.slice(1, cluster.length - 1),
  //       travelMode: "DRIVING",
  //     };
  //   });

  //   console.log(directionsRequests, "DIRECTIONS REQUETS");

  //   //send requests
  //   const getDirections = (request) =>
  //     directionsService.route(request, (result, status) => {
  //       console.log("STATUS", status);
  //       if (status === "OK") {
  //         createPolyline(result);
  //       } else {
  //         delayFactor++;
  //         // if we have more than 10 requests per second we will get "OVER_QUERY_LIMIT" status, after that we can only do one request per second
  //         // in that case we want to send the remaining requwsts out 1 per 1 second
  //         const timeoutId = setTimeout(
  //           () => getDirections(request),
  //           delayFactor * 1000
  //         );
  //         timeoutIds.push(timeoutId);
  //       }
  //     });
  //   directionsRequests.forEach(getDirections);

  //   // creating a polyline

  //   const createPolyline = (directionsResult) => {
  //     const coordsLegs = directionsResult.routes[0].legs.reduce((acc, leg) => {
  //       // array of coords form all steps
  //       const coordsSteps = leg.steps.reduce((acc, step) => {
  //         // array of coords from each step
  //         const coordsStep = step.path;

  //         return [...acc, ...coordsStep];
  //       }, []);

  //       return [...acc, ...coordsSteps];
  //     }, []);

  //     setPolylineCoords((polylineCoords) => [...polylineCoords, ...coordsLegs]);
  //   };

  //   // clear potential timeouts for getting directions when unmounting
  //   return () => {
  //     timeoutIds.forEach((id) => {
  //       clearTimeout(id);
  //     });
  //   };
  // }, []);

  return (
    <section className={"section-route-planner"} ref={mapContainerRef}>
      <Map
        google={google}
        initialCenter={currentPosition}
        containerStyle={containerStyle}
        onClick={onMapClick}
        zoom={12}
        zoomControlOptions={{
          position: google.maps.ControlPosition.RIGHT_TOP,
        }}
        disableDefaultUI //remove all controls
        zoomControl
      >
        {locationMarkers}

        {currentPositionMarker}

        <InfoWindow
          marker={activeMarker}
          visible={showInfoWindow}
          onClose={() => setShowInfoWindow(false)}
        >
          <div>
            <h3>{activeMarkerName}</h3>
            <p>{activeMarkerAddress}</p>
          </div>
        </InfoWindow>

        {pickupState.polylineCoords && (
          <Polyline
            path={pickupState.polylineCoords}
            strokeColor="#0073b1"
            strokeOpacity={0.6}
            strokeWeight={3}
          />
        )}
      </Map>
    </section>
  );
};

const RoutePlannerWithDeps = compose(
  withGoogleAPI,
  withCurrentPosition
)(RoutePlanner);

// using a container on top, to show nothing when we have no state, and avoid giving pickup state to the Auth Component
const RoutePlannerContainer = withPickupStore(({ pickupState, windowHeight }) =>
  pickupState.pending ? (
    <RoutePlannerWithDeps
      pickupState={pickupState}
      windowHeight={windowHeight}
    />
  ) : (
    <ListNoResults
      message={"Er zijn geen locaties in de database voor deze datum. "}
    />
  )
);

export default RoutePlannerContainer;
