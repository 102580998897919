import * as React from "react";

export const InputField = ({
  className,
  value,
  setValue,
  label,
  labelClassName,
  name,
  containerClassName
}) => (
  <div className={containerClassName}>
    <label className={labelClassName}>{label}</label>
    <input
      className={className}
      value={value}
      onChange={setValue}
      name={name}
    ></input>
  </div>
);
