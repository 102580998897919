import React from "react";
import format from "date-fns/format";
import { compose } from "recompose";

import CompletedList from "./CompletedList";
import SwalNotification from "../Swal";

import { withAuthorization } from "../Session";
import { withCurrentPosition } from "../Geolocation";

import withPickupStore from "../PickupStore/withPickupStore";
import Loader from "../Loader";

class CompletedPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInput: "",
      showAlert: false,
      alertMessage: "",
      alertStatus: "",
      alertError: "",
      showSpinner: false,
    };
  }

  // LIFECYCLE METHODS //
  componentDidMount() {
    this.completedSection.style.height = `calc(${this.props.windowHeight}px - 9rem)`;
    //When component mounts we fetch our routes from firestore by using our route method from our firebase instance.
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // always change the height of map, if window height changes
    if (prevProps.windowHeight !== this.props.windowHeight) {
      this.completedSection.style.height = `calc(${this.props.windowHeight}px - 9rem)`;
    }
  }

  // HELPER METHODS //
  showPickups = (snapshot) => {
    const results = snapshot.data();

    if (results === undefined) {
      this.setState({ processed: undefined });
      return;
    }

    const pickups = results.pickups;
    //Creating an array with the route objects we get back.
    const processedPickups = Object.keys(pickups).map((key) => {
      return {
        ...pickups[key],
        index: Number(key),
      };
    });

    // before removing duplicates and filtering based on completed/ discarded save initial pickups as they come form firestore to reference later via associated pickups field
    this.setState({ initialPickups: processedPickups });

    const processedPickupsFiltered = processedPickups.filter((pickup) => {
      return pickup.completed || pickup.discarded;
    });

    const processedPickupsNoDouble = this.removeDuplicatesSafeOrder(
      processedPickupsFiltered
    );

    // sort according to time completed

    const sortedPickups = processedPickupsNoDouble.sort(
      (a, b) => b.time.seconds - a.time.seconds
    );

    this.setState({ processed: sortedPickups });
  };

  removeDuplicatesSafeOrder(arr) {
    var seen = {};
    var retArr = [];
    for (var i = 0; i < arr.length; i++) {
      console.log(seen, "SEEN OBJECT");
      if (!(`${arr[i].address.street},${arr[i].address.name}` in seen)) {
        retArr.push(arr[i]);
        seen[`${arr[i].address.street},${arr[i].address.name}`] = arr[i].index;
      } else {
        const pickupToAddFieldTo = retArr.find(
          (pickup) =>
            pickup.index ===
            seen[`${arr[i].address.street},${arr[i].address.name}`]
        );

        pickupToAddFieldTo.associatedPickups =
          pickupToAddFieldTo.associatedPickups
            ? pickupToAddFieldTo.associatedPickups.concat([arr[i].index])
            : [arr[i].index];
      }
    }
    console.log(retArr);
    return retArr;
  }

  recoverPickup = (pickupIndex, isDiscarded) => {
    // show spinner
    this.setState({ showSpinner: true });
    const selectedPickup = this.props.pickupState.processed.find(
      (pickup) => pickup.index === pickupIndex
    );
    const status = isDiscarded ? "discarded" : "completed";

    const dataToWriteSingle = {
      //e.g. '0.completed' will update to false.
      [`pickups.${pickupIndex}.${status}`]: false,
      //e.g. '0.time' will update to current time.
      [`pickups.${pickupIndex}.time`]: null,
    };

    //  check if there are asscociated pickups with this address and if so add them to the doc write
    if (selectedPickup.associatedPickups) {
      const extraPickupData = selectedPickup.associatedPickups.map(
        (pickupIndex) => {
          return {
            [`pickups.${pickupIndex}.${status}`]: false,
            [`pickups.${pickupIndex}.time`]: null,
          };
        }
      );

      var dataToWriteWithExtras = extraPickupData.reduce((acc, pickup) => {
        return { ...acc, ...pickup };
      }, dataToWriteSingle);
    }

    const dataToWrite = dataToWriteWithExtras
      ? dataToWriteWithExtras
      : dataToWriteSingle;

    const docRef = this.props.firebase.route(
      this.props.authUser.uid,
      this.props.pickupState.date
    );

    Promise.resolve()
      .then(() => {
        if (selectedPickup.extraPickup) {
          return this.props.firebase.db
            .collection("amsterdam")
            .doc("routes")
            .collection(this.props.pickupState.date)
            .doc("pickups")
            .collection("extraPickups")
            .doc(selectedPickup.extraPickupId)
            .update({
              [status]: false,
              time: null,
            });
        } else {
          return docRef.update({ ...dataToWrite });
        }
      })
      .then(() => {
        // update completed pickups info
        const userStatusFirestoreRef = this.props.firebase.db.doc(
          `/amsterdam/driverSummary/${this.props.authUser.uid}/routeProgress`
        );

        // return promise to make its handling part of chain
        // if pickup has associated contracts we need to acccount for them
        return userStatusFirestoreRef.update({
          completed: selectedPickup.associatedPickups
            ? this.props.firebase.FieldValue.increment(
                -1 - selectedPickup.associatedPickups.length
              ) // 1 for the current pickup + the associated contracts
            : this.props.firebase.FieldValue.increment(-1),
          pending: selectedPickup.associatedPickups
            ? this.props.firebase.FieldValue.increment(
                1 + selectedPickup.associatedPickups.length
              )
            : this.props.firebase.FieldValue.increment(1),
        });
      })
      .then(() => {
        // check if selected pickup or associated ones have post calculation containers
        let allPickups;
        if (selectedPickup.associatedPickups) {
          allPickups = selectedPickup.associatedPickups.reduce(
            (acc, pickupIndex) => {
              const extraPickup = this.props.pickupState.raw.find(
                (pickup) => pickup.index === pickupIndex
              );
              return [...acc, extraPickup];
            },
            [selectedPickup]
          );
        } else {
          allPickups = [selectedPickup];
        }

        if (
          allPickups.filter((pickup) => pickup.address.postCalculation === true)
            .length > 0
        ) {
          console.log("INSIDE DELETION");
          // if selected pickup is an extra pickup then delte post calc items that are only connected to this extra pickup
          // this seperation is done in order to avoid also deleting the post calc containers of NOT extra pickups and vice versa
          if (selectedPickup.extraPickup) {
            return this.props.firebase.db
              .collection("items")
              .where("clientId", "==", selectedPickup.address.userId)
              .where("driverId", "==", this.props.authUser.uid)
              .where("linkedTo", "==", selectedPickup.extraPickupId)
              .where("pickupDate", "==", format(new Date(), "y-MM-dd"))
              .get()
              .then((querySnapshot) => {
                const proms = [];
                querySnapshot.forEach((doc) => {
                  proms.push(doc.ref.delete());
                });

                return Promise.all(proms);
              });
          } else {
            // if so delete all post calc entries in collection 'items' related to the client of pickup
            return this.props.firebase.db
              .collection("items")
              .where("clientId", "==", selectedPickup.address.userId)
              .where("driverId", "==", this.props.authUser.uid)
              .where("pickupDate", "==", format(new Date(), "y-MM-dd"))
              .get()
              .then((querySnapshot) => {
                const proms = [];
                querySnapshot.forEach((doc) => {
                  proms.push(doc.ref.delete());
                });

                return Promise.all(proms);
              });
          }
        } else {
          // if we dont have any post calc pickups then return a resolved promise to keep the chain going
          return Promise.resolve();
        }
      })
      .then(async () => {
        // check if pickup is distribution pickup, in which case we also need to revert the items in distribution collection to not completed
        if (selectedPickup.distributionPickup) {
          // get all pickups associated
          let allPickups;

          if (selectedPickup.associatedPickups) {
            allPickups = selectedPickup.associatedPickups.reduce(
              (acc, pickupIndex) => {
                const associatedPickup = this.props.pickupState.raw.find(
                  (pickup) => pickup.index === pickupIndex
                );
                return [...acc, associatedPickup];
              },
              [selectedPickup]
            );
          } else {
            allPickups = [selectedPickup];
          }

          // turn items back to not completed
          const distrItemsProms = allPickups.map((pickup) =>
            this.props.firebase.updateDistribution(pickup.distrPickupId, {
              completed: false,
              time: null,
            })
          );

          // also delete completed notification
          // in this case only the selectedPickup has been used to create the notification for this address
          const deleteNotifProm = this.props.firebase.deleteDistrNotification(
            selectedPickup.distrPickupId
          );

          await Promise.all([...distrItemsProms, deleteNotifProm]);

          console.log("distr items updated and notif deleted");
        }
      })
      .then(() => {
        // update local state
        console.log(selectedPickup, "SELCTED IN COMPLETED");
        if (selectedPickup.alternative) {
          delete selectedPickup.alternative;
        }

        this.props.dispatchPickupState({
          type: "BRING_BACK_PICKUP",
          payload: {
            selectedPickup: {
              ...selectedPickup,
              time: null,
              [status]: false,
            },
            curPos: this.props.curPos,
          },
        });

        this.setState({
          showAlert: true,
          alertMessage: `${selectedPickup.address.name} hersteld.`,
          alertStatus: "Success",
          showSpinner: false, // hide spinner
        });
      })
      .catch((error) => {
        // catch errors from chain here
        this.setState({
          showAlert: true,
          alertMessage: `Er is een probleem opgetreden bij het ophalen van uw pick-up.`,
          alertError: `Error: ${error.message}`,
          alertStatus: "Failure",
          showSpinner: false, // hide spinner
        });
      });
  };

  hideAlert = () => {
    if (this.state.showAlert) {
      return setTimeout(() => {
        this.setState({ showAlert: false });
      }, 2000);
    }
  };

  closeAlert = () => {
    this.setState({ showAlert: false });
  };

  handleSearchInput = (e) => {
    this.setState({
      searchInput: e.target.value,
    });
  };

  render() {
    const {
      processed,
      date,
      showAlert,
      alertMessage,
      alertStatus,
      alertError,
      searchInput,
      showSpinner,
    } = this.state;

    const { curPos } = this.props;
    console.log(this.props.pickupState.processed, "PROCESSED");
    return (
      <main>
        <section
          className="section-completed"
          ref={(node) => (this.completedSection = node)}
        >
          {/* If there are routes in our array then render PendingList component. */}
          {this.props.pickupState.processed !== null ? (
            <CompletedList
              date={date}
              processed={this.props.pickupState.processed}
              currentPosition={curPos}
              recoverPickup={this.recoverPickup}
              searchInput={searchInput}
              handleSearchInput={this.handleSearchInput}
            />
          ) : (
            <Loader message={"Laden van ophaaladressen..."} />
          )}

          {showAlert && (
            <SwalNotification
              message={alertMessage}
              alertStatus={alertStatus}
              error={alertError}
              hideAlert={this.hideAlert}
              closeAlert={this.closeAlert}
            />
          )}
          {showSpinner && <Loader message={"Laden..."} actionsLoader />}
        </section>
      </main>
    );
  }
}

const CompletedPageWithCurPos = compose(
  withPickupStore,
  withCurrentPosition
)(CompletedPage);
//If the condition fails, we redirect to Login page.
const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(CompletedPageWithCurPos);
