import React from "react";
import { withFirebase } from "../Firebase";

import Loader from "../Loader";
import Alert from "../Alert";
import SwalNotification from "../Swal";
import format from "date-fns/format";

import LocationContext from "./context";

const navigatorOptions = {
  enableHighAccuracy: true,
  timeout: 15000,
  maximumAge: 0,
};

//Creating higher-order component to be used to pass the auth value into a component.
//It will also handle the session for the authenticated user to keep App component clean.

class LocationStore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLocation: null,
      currentLocationControlled: null,
      showAlert: false,
      alertMessage: "",
      alertStatus: "",
      alertError: "",
    };

    this.intervalId = null;
  }

  componentDidMount() {
    this.getCurPos();
    //set a set interval that sends new state and data to firestore every 15 seconds
    this.intervalId = setInterval(() => this.getCurPos(), 10000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
    this.intervalId = null;
  }

  getCurPos() {
    console.log('cur pos runs')
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const coords = pos.coords;
          //If there isnt a state yet, it means we dont have current position yet,
          //so we cant compare the last position with the current one.
          if (!this.state.currentLocation) {
            this.setState(
              {
                currentLocation: {
                  latitude: coords.latitude,
                  longitude: coords.longitude,
                  accuracy: coords.accuracy,
                },
              },
              () => {
                if (this.state.currentLocation.accuracy <= 50) {
                  this.updateUserLocationStatus(this.state.currentLocation);
                }
              }
            );
          } else {
            //If there is a state, we can compare the current position with the last,
            //and only if its different we can reset the state with the new one, which
            //will then trigger the component did update on all the componets that use this components state as prop.
            if (
              this.state.currentLocation.latitude !== coords.latitude ||
              this.state.currentLocation.longitude !== coords.longitude
            ) {
              this.setState(
                {
                  currentLocation: {
                    latitude: coords.latitude,
                    longitude: coords.longitude,
                    accuracy: coords.accuracy,
                  },
                },
                () => {
                  if (this.state.currentLocation.accuracy <= 50) {
                    this.updateUserLocationStatus(this.state.currentLocation);
                  }
                }
              );
            }
          }
        },
        (error) => {
          this.setState({
            showAlert: true,
            alertStatus: "Failure",
            alertMessage:
              "Please enable location access on your device and refresh page to use application.",
            alertError: `Error: ${error.message}`,
          });
          if (error.message.startsWith("Only secure origins are allowed")) {
            console.log("Secure Origin issue.");
          }
        },
        navigatorOptions
      );
    }
  }

  updateUserLocationStatus = async ({ latitude, longitude, accuracy }) => {
    const uid = this.props.firebase.auth.currentUser.uid;
    const userCurrentLocationRef = this.props.firebase.db.doc(
      `/amsterdam/driverSummary/${uid}/currentGpsLocation`
    );
    const userRouteCoordsRef = this.props.firebase.db.doc(
      `/amsterdam/driverSummary/${uid}/routeGpsCoords/`
    );

    const curPos = {
      lat: latitude,
      lng: longitude,
      acc: accuracy,
      time: this.props.firebase.timeStamp.now(),
    };
    //set driver current location
    const prom1 = userCurrentLocationRef.set(curPos);
    // store each recorded gps position
    const prom2 = userRouteCoordsRef
      .collection(format(new Date(), "y-MM-dd"))
      .add(curPos);

    try {
      const [res1, res2] = await Promise.all([prom1, prom2]);

      console.log(res1, res2.id, "RES");
    } catch (error) {
      console.log(error);
    }
  };

  closeAlert = () => {
    this.setState({ showAlert: false });
  };

  render() {
    const { showAlert, alertError, alertMessage, alertStatus } = this.state;

    return this.state.currentLocation ? (
      <LocationContext.Provider value={this.state.currentLocation}>
        {showAlert && (
          <SwalNotification
            message={alertMessage}
            alertStatus={alertStatus}
            error={alertError}
            closeAlert={this.closeAlert}
          />
        )}
        {this.props.children}
      </LocationContext.Provider>
    ) : (
      <Loader message={"Geolocatie laden..."} />
    );
  }
}

export default withFirebase(LocationStore);
