import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Loader = (props) => (
  <div
    className={`loader ${props.context ? "loader--local" : ""} ${
      props.actionsLoader ? "loader--actions" : ""
    }`}
  >
    <div className="loader__container">
      <FontAwesomeIcon className="loader__icon" icon="circle-notch" />{" "}
      <p className="loader__message">
        {props.message ? props.message : "Laden van google maps..."}
      </p>
    </div>
  </div>
);

export default Loader;
