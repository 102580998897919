import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { compose } from "recompose";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import AuthUserContext from "../Session";

// import { PasswordForgetLink } from '../PasswordForget';

//IMAGES
import logo from "../../assets/img/logo.svg";
import logoRoute from "../../assets/img/logoRoute.svg";
import wave from "../../assets/img/wave.svg";

//Icons component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LoginPage = (props) => (
  <AuthUserContext.Consumer>
    {(authUser) => {
      if (authUser) {
        props.history.push(ROUTES.PENDING);
      } else {
        return (
          <main>
            <div className="login">
              <div className="login__logo-box u-margin-bottom-xlarge">
                <img src={logo} alt="dispatchX logo" className="login__logo" />
              </div>
              <div className="login__text-box u-margin-bottom-xlarge">
                <h1 className="heading-primary">Hallo</h1>
                <h3 className="heading-tertiary">Log in bij uw account</h3>
              </div>

              <LoginForm />
            </div>
            <img src={logoRoute} alt="dispatchX logo" className="logo-route" />
            <img src={wave} alt="wave simulating graphic" className="wave" />
          </main>
        );
      }
    }}
  </AuthUserContext.Consumer>
);

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
};

class LoginFormBase extends Component {
  constructor(props) {
    super(props);
    //Using INITIAL_STATE object to be able to reset state when user logs in.
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then((user) => {
        //Resetting state.
        this.setState({ ...INITIAL_STATE });
        //Rederecting logged in user to home page using React Router's history object.
        this.props.history.push(ROUTES.PENDING);
      })
      .catch((error) => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    return (
      <div className="login__form-box">
        <form className="login__form" onSubmit={this.onSubmit}>
          <label className="login__label" htmlFor="email">
            Email
          </label>
          <div className="login__input-box">
            <input
              className="login__input"
              name="email"
              value={email}
              id="email"
              type="email"
              onChange={this.onChange}
              required
            />
            <FontAwesomeIcon className="login__input--icon" icon="envelope" />
          </div>

          <label className="login__label" htmlFor="password">
          Wachtwoord
          </label>
          <div className="login__input-box">
            <input
              className="login__input"
              name="password"
              value={password}
              id="password"
              type="password"
              onChange={this.onChange}
              required
            />
            <FontAwesomeIcon className="login__input--icon" icon="unlock-alt" />
          </div>
          {error && (
            <p className="login__error">Gebruikersnaam en / of wachtwoord onjuist.</p>
          )}
          {/* <PasswordForgetLink /> */}
          <div className="login__btn-box">
            <button className="btn btn--login" type="submit">
            Inloggen
            </button>
          </div>
        </form>
        {/* just for creating a user when we need to */}
        {/* <Link to={ROUTES.SIGN_UP}> Sign up</Link> */}
      </div>
    );
  }
}

//WITH FIREBASE
//Wrapping LoginFormBase component with 'withFirebase' higher-order component.
//That way LoginForm has access to the Firebase instance via the higher-order component,
//and we don't have to pass the instance directly into it. Instead we pass the component we want to couple it with.
//WITH ROUTER
//React Router package provides a higher-order component(withRouter) to make router properties available in the props of a component.
//COMPOSE
//Using recompose to compose the higher-order functions and nest one into the other.

const LoginForm = compose(withRouter, withFirebase)(LoginFormBase);

export default withRouter(LoginPage);

export { LoginForm };
