import React from "react";
import { getDistance, convertDistance } from "geolib";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import format from "date-fns/format";

const CompletedListItem = (props) => {
  //This is the index of item in the array we 're looping over. Not to be confused with index of pickup in firestore.
  const pickup = props.value;
  const isDiscarded = pickup.discarded;

  const { latitude, longitude } = props.currentPosition;
  const distanceFromTarget = getDistance(
    { latitude, longitude },
    {
      latitude: props.value.address.lat,
      longitude: props.value.address.lng,
    }
  );
  const distanceInKm = convertDistance(distanceFromTarget, "km").toFixed(2);

  return (
    <li className="pickup">
      <div className="pickup__overlay"></div>
      <div className="pickup__left-column">
        <div className="pickup__left-column-container">
          <div className="pickup__first-row">
            <div className="pickup__ETA-container">
              {/* if pickup is discarded we do not currently allow to recover */}
              {isDiscarded ? null : (
                <button
                  className="pickup__btn pickup__btn--recover"
                  onClick={() => props.recoverPickup(pickup.index, isDiscarded)}
                >
                  <FontAwesomeIcon
                    className="pickup__icon pickup__icon--recover"
                    icon="history"
                  />
                </button>
              )}
            </div>
            <div className="pickup__address-container">
              <p className="pickup__name">
                {pickup.address.name} - {distanceInKm} km
              </p>
              <p className="pickup__street"> {pickup.address.street}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="pickup__right-column">
        {isDiscarded ? (
          <span className="pickup__alt-label pickup__alt-label--discarded">
            Weggegooid
          </span>
        ) : (
          <span className="pickup__alt-label pickup__alt-label--completed">
            Voltooid
          </span>
        )}

        <div className="pickup__time-container">
          <FontAwesomeIcon
            className="pickup__icon pickup__icon--clock"
            icon={["far", "clock"]}
          />

          <p className="pickup__time">{format(pickup.time.toDate(), "p")}</p>
        </div>
      </div>
    </li>
  );
};

export default CompletedListItem;
