import React from 'react';

const FormPage = (props) => (
	<div className="form__container" >
		<form className="form" onSubmit={props.onSubmit}>
			{props.children}
			<button className="btn btn--form">Submit</button>
		</form>
	</div>
);

const FormPageStart = (props) => {
	return (
		<FormPage onSubmit={(e) => props.handleDriverForm(e, props.index)}>
			<label className="form__label" htmlFor="numberDrivers">
			Veld 1
			</label>
			<input className="form__input" type="text" name="numberDrivers" id="numberDrivers" />
			<label className="form__label" htmlFor="typeCar">
			Veld 2
			</label>
			<input className="form__input" type="text" name="typeCar" id="typeCar" />
			<label className="form__label" htmlFor="specialParam">
			Veld 3
			</label>
			<input className="form__input" type="text" name="specialParam" id="specialParam" />
		</FormPage>
	);
};

const FormPageEnd = (props) => {
	return (
		<FormPage onSubmit={(e) => props.handleDriverForm(e, props.index)}>
			<label className="form__label" htmlFor="numberDrivers">
			Veld 1
			</label>
			<input className="form__input" type="text" name="numberDrivers" id="numberDrivers" />
			<label className="form__label" htmlFor="typeCar">
			Veld 2
			</label>
			<input className="form__input" type="text" name="typeCar" id="typeCar" />
			<label className="form__label" htmlFor="specialParam">
			Veld 3
			</label>
			<input className="form__input" type="text" name="specialParam" id="specialParam" />
		</FormPage>
	);
};

export { FormPageStart, FormPageEnd };
