import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withFirebase } from "../Firebase";
import { Textarea } from "../Textarea";
import { resizeImage } from "./services";

class Dialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideDialog: false,
    };
    this.timeoutId = null;
  }

  handleClose = (e) => {
    console.log("handle close fired");
    console.log(e.target.className);
    if (
      e.target.className === "dialog__container" ||
      e.target.className === "btn-dialog btn-dialog--decline"
    ) {
      this.setState({ hideDialog: true });
      // this is to allow the animation to run before unmounting
      this.timeoutId = setTimeout(() => this.props.handleClose(), 80);
    }
  };

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  render() {
    const { title, subtitle, wide, children } = this.props;
    const { hideDialog } = this.state;
    return (
      <div className="dialog__container" onClick={this.handleClose}>
        <div className={`dialog ${hideDialog ? "dialog--hide" : ""} ${wide ? "dialog-wide" : ""}`}>
          <h1 className="dialog__header">
            {title}
            {subtitle}
          </h1>

          {/* <div className="dialog__text-box">
			{props.icon}
			<p className="dialog__message">{props.message} </p>
			{props.time && <p className="dialog__time">{props.time}</p>}
		</div> */}
          {children}
        </div>
      </div>
    );
  }
}

class NoteDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTextarea: false,
      customMsg: null,
      autoMsg: null,
    };
  }

  toggleTextarea = (autoMsg) => {
    this.setState({
      showTextarea: !this.state.showTextarea,
      autoMsg: autoMsg,
      customMsg: "",
    });
  };

  handleTextArea = (e) => {
    this.setState({ customMsg: e.target.value });
  };

  handleAutoMsg = (autoMsg) => {
    this.setState({ autoMsg: autoMsg });
  };

  handleSubmit = async (e) => {
    console.log('///////////////////////////////////')
    console.log(this.state.autoMsg)
    e.preventDefault();
    // keep event intact to use in handleSubmitNoteAndDiscard
    e.persist();
    const selectedPickup = this.props.selectedPickup;
    // if we are dealing with discarding distribution items we need to
    // attach to the driver msg the distribution items for all the pickups of the location
    if (selectedPickup.distributionPickup) {
      let allPickups;

      if (selectedPickup.associatedPickups) {
        allPickups = selectedPickup.associatedPickups.reduce(
          (acc, pickupIndex) => {
            const associatedPickup = this.props.initialPickups.find(
              (pickup) => pickup.index === pickupIndex
            );
            return [...acc, associatedPickup];
          },
          [selectedPickup]
        );
      } else {
        allPickups = [selectedPickup];
      }

      var items = allPickups
        .map(
          (pickup) =>
            `${pickup.address.container} x ${pickup.address.containerCount}, `
        )
        .join("");

      console.log(items, "ITEMS TO BE ADDED TO NOTE");
    }

    this.props.handleSubmitNoteAndDiscard(
      this.props.index,
      e,
      this.props.type === "note" ? false : true,
      !selectedPickup.distributionPickup
        ? this.state.customMsg
        : `Bericht: ${this.state.customMsg}, Items: ${items}`,
      this.state.autoMsg
    ); // boolean is for isDiscarded
  };

  render() {
    const isDistributionPickup = this.props.selectedPickup.distributionPickup;
    let notifications = isDistributionPickup
      ? this.props.generalInfo.dispatchxDistributionNotifications
      : this.props.generalInfo.dispatchxNotifications;

    const type = this.props.type;
    const msgs =
      type === "note"
        ? notifications.filter(
            (item) =>
              item.class ===
              (isDistributionPickup ? "distributie meldingen" : "meldingen")
          )
        : notifications.filter(
            (item) =>
              item.class ===
              (isDistributionPickup ? "distributie verwerpen" : "verwerpen")
          );

    return (
      <Dialog
        title={this.props.type === "note" ? "Notitie voor " : "Verwerpen "}
        subtitle={this.props.name}
        handleClose={this.props.handleCancelNoteAndDiscard}
      >
        <form className="dialog__form" onSubmit={this.handleSubmit}>
          {!this.state.showTextarea && (
            <>
              <div className="dialog__option-container">
                {msgs.map((msg, index) => {
                  if (msg.type !== "overig") {
                    return (
                      <button
                        className="dialog__option"
                        name="noteMsg"
                        id={index}
                        key={msg.type}
                        onClick={() =>
                          msg.description
                            ? this.toggleTextarea(msg)
                            : this.handleAutoMsg(msg)
                        }
                      >
                        <FontAwesomeIcon
                          className="dialog__icon "
                          icon={msg.icon.substring(msg.icon.indexOf("-") + 1)}
                        />
                        <p className="dialog__disclaimer">{msg.type}</p>
                      </button>
                    );
                  } else {
                    return (
                      <button
                        className="dialog__option"
                        type="button"
                        name="showTextarea"
                        onClick={() => this.toggleTextarea(msg)}
                        key={msg.type}
                      >
                        <FontAwesomeIcon
                          className="dialog__icon "
                          icon={msg.icon.substring(msg.icon.indexOf("-") + 1)}
                        />
                        <p className="dialog__disclaimer">{msg.type}</p>
                      </button>
                    );
                  }
                })}
              </div>
              <button
                className="btn-dialog btn-dialog--decline"
                type="button"
                name="decline"
              >
                Sluit
              </button>
            </>
          )}

          {this.state.showTextarea && (
            <>
              {this.state.autoMsg.photo && (
                <PhotoAdder handleDriverPhoto={this.props.handleDriverPhoto} mandatoryPhoto={this.state.autoMsg.photoMandatory}/>
              )}
              <div className="dialog__message-box">
                <Textarea
                  rows="5"
                  cols="26"
                  className="dialog__textarea"
                  name="customMsg"
                  placeholder={`Schrijf tekst hier... ${
                    this.state.autoMsg.type !== "overig" ? "(optioneel)" : ""
                  }`}
                  value={this.state.customMsg}
                  onChange={this.handleTextArea}
                />
                <button className="btn-dialog btn-dialog--accept" name="accept">
                  Opslaan
                </button>
              </div>
            </>
          )}
        </form>
      </Dialog>
    );
  }
}

const PhotoAdder = ({ handleDriverPhoto, mandatoryPhoto}) => {
  const handlePhotoUpload = async (e) => {
    // e.target.files[0]
    const config = {
      file: e.target.files[0],
      maxSize: 1000,
    };

    const resizedImg = await resizeImage(config);

    handleDriverPhoto(resizedImg);
  };

  const textField = `Voeg foto aan melding toe ${mandatoryPhoto ? '(verplicht)' : '(optioneel)'}`

  return (
    <div className={"dialog__photo-adder-container"}>
      <label htmlFor={"photo-upload"} className={"dialog__photo-adder-label"}>
        {textField}
      </label>
      <input
        id="photo-upload"
        className={"dialog__photo-adder-input"}
        type="file"
        accept="image/*"
        capture="environment"
        onChange={handlePhotoUpload}
      />
    </div>
  );
};

const NoteDialogWithFb = withFirebase(NoteDialog);

export default Dialog;

export { NoteDialogWithFb };
