import React, { useEffect, useMemo, useReducer, useRef, useState } from "react";
import PickupStoreContext from "./context";
import { withAuthorization } from "../Session";
import { removeDuplicatesSafeOrder, getClosestPickup } from "./helpers";
import Loader from "../Loader";
import format from "date-fns/format";
import withTimeStore from "../TimeStore/withTimeStore";
import { convertTimeToSecs } from "../TimeStore/helpers";
import {
  ExpiringNotifier,
  PostponedNotifier,
  ProgressNotifier,
} from "../Notifier";
import { withCurrentPosition } from "../Geolocation";
import { compose } from "recompose";
import { getDistance } from "geolib";
import ExtrasNotifications from "../ExtrasNotifications";

const initialState = {
  pending: null,
  rawPickupsNoDouble: null,
  processed: null,
  raw: null,
  expiring: [],
  deleted: [], // here we add all the pickups that are taken away in clientx from the driver's initial route
  extrasNotifications: [],
  removedNotifications: [],
  isLoading: true,
  isError: false,
  error: null,
  date: format(new Date(), "y-MM-dd"),
  polylineCoords: [],
};

const pickupStoreReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        raw: action.payload.raw,
        pending: action.payload.pending,
        processed: action.payload.processed,
        rawPickupsNoDouble: action.payload.rawPickupsNoDouble,
        polylineCoords: action.payload.polylineCoords,
        isLoading: false,
      };
    case "FETCH_FAILURE":
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
      };
    case "PROCESS_PICKUP":
      return {
        ...state,
        processed: [...state.processed, action.payload.selectedPickup],
        pending: determineClosest({
          curPos: action.payload.curPos,
          pending: state.pending.filter(
            (item) => item.index !== action.payload.selectedPickup.index
          ),
        }),
      };
    case "BRING_BACK_PICKUP":
      return {
        ...state,
        processed: state.processed.filter(
          (item) => item.index !== action.payload.selectedPickup.index
        ),
        pending: determineOrderIncomingPickups(action.payload, state),
      };
    case "SET_CLOSEST":
      return {
        ...state,
        pending: determineClosest(action.payload),
      };
    case "SET_EXPIRING":
      return {
        ...state,
        expiring: action.payload,
      };
    case "ADD_EXTRA_PICKUP_PENDING":
      return {
        ...state,
        pending: determineOrderIncomingPickups(action.payload, state),
      };
    case "ADD_EXTRA_PICKUP_PROCESSED":
      return {
        ...state,
        processed: [...state.processed, action.payload],
      };
    case "DELETE_EXTRA_PICKUP":
      return {
        ...state,
        pending: determineClosest({
          curPos: action.payload.curPos,
          pending: state.pending.filter(
            (item) => item.extraPickupId !== action.payload.extraPickupId
          ),
        }),
        processed: state.processed.filter(
          (item) => item.extraPickupId !== action.payload.extraPickupId
        ),
      };
    case "DELETE_PICKUP": {
      // check if pickup we re about to delete is part of pending or processed
      const partOfPending = state.pending.find(
        (item) => item.id === action.payload.selectedPickup.id
      );
      const deletedPickup = partOfPending
        ? state.pending.find(
            (item) =>
              item.id === action.payload.selectedPickup.id && !item.alternative
          )
        : state.processed.find(
            (item) =>
              item.id === action.payload.selectedPickup.id && !item.alternative
          );
      return {
        ...state,
        pending: determineClosest({
          curPos: action.payload.curPos,
          pending: state.pending.filter(
            (item) => item.id !== action.payload.selectedPickup.id
          ),
        }),
        processed: state.processed.filter(
          (item) => item.id !== action.payload.selectedPickup.id
        ),
        deleted: [...state.deleted, deletedPickup],
      };
    }
    case "BRING_BACK_DELETED_PICKUP": {
      // determine if it should enter pending or processed
      const partOfPending =
        !action.payload.selectedPickup.completed &&
        !action.payload.selectedPickup.discarded;
      // get the pickup we re trying to get back from the deleted array in state obj
      const removedPickupLocally = state.deleted.find(
        (item) => item.id === action.payload.selectedPickup.id
      );
      return {
        ...state,
        pending: partOfPending
          ? determineOrderIncomingPickups(
              {
                selectedPickup: removedPickupLocally,
                curPos: action.payload.curPos,
              },
              state
            )
          : state.pending,
        processed: partOfPending
          ? state.processed
          : [...state.processed, removedPickupLocally],
        deleted: state.deleted.filter(
          (item) => item.id !== action.payload.selectedPickup.id
        ),
      };
    }
  }
};

const determineClosest = ({ curPos, pending }) => {
  //Make sure that there are not any 'alternative' routes inside the list that will throw off setting a new alt route when reloading our page.
  const pendingPickupsFiltered = pending.filter((pickup) => {
    return pickup.alternative !== true;
  });

  //If our remaining pickups are more than two then search for alt pickup, and if we find one that is not already in second spot, move it up.
  if (pendingPickupsFiltered.length > 2) {
    const closestPickup = getClosestPickup(curPos, pendingPickupsFiltered);
    if (closestPickup) {
      if (closestPickup.index !== pendingPickupsFiltered[1].index) {
        pendingPickupsFiltered.splice(1, 0, closestPickup);
      }
    }
  }

  return pendingPickupsFiltered;
};

const determineOrderIncomingPickups = ({ selectedPickup, curPos }, state) => {
  console.log("determine is called");
  const pending = state.pending.slice();
  //Make sure that there are not any 'alternative' routes inside the list that will throw off setting a new alt route when reloading our page.
  const pendingPickupsFiltered = pending.filter((pickup) => {
    return pickup.alternative !== true;
  });

  // get closest pickup to the postponed pickup coming back
  const nearestPickupToPostponed = getClosestPickup(
    {
      latitude: selectedPickup.address.lat,
      longitude: selectedPickup.address.lng,
    },
    pending,
    curPos,
    "incoming"
  );

  if (nearestPickupToPostponed) {
    console.log(nearestPickupToPostponed, "NEAREST PICKUP");
    // if nearest is our cur location then we show incoming pickup on top of list
    if (nearestPickupToPostponed.index === "CUR POS") {
      pendingPickupsFiltered.splice(0, 0, selectedPickup);
    } else {
      // match that closest pickup to our array
      const nearest = pendingPickupsFiltered.find(
        (item) => item.index === nearestPickupToPostponed.index
      );

      delete nearestPickupToPostponed.alternative;
      // put postponed pickup after the index of the closest pickup, if its further away than distance from curPos, else put it before
      const distanceFromCurPosIncomingPickup = getDistance(
        { latitude: curPos.latitude, longitude: curPos.longitude },
        {
          latitude: selectedPickup.address.lat,
          longitude: selectedPickup.address.lng,
        }
      );
      const distanceFromCurPosNearestPickup = getDistance(
        { latitude: curPos.latitude, longitude: curPos.longitude },
        {
          latitude: nearest.address.lat,
          longitude: nearest.address.lng,
        }
      );

      if (distanceFromCurPosIncomingPickup < distanceFromCurPosNearestPickup) {
        if (pendingPickupsFiltered.indexOf(nearest) - 1 < 0) {
          // if the nearest pickup is the first one in the list then we add it on top of list, instead of adding it in the -1 index spot
          pendingPickupsFiltered.splice(0, 0, selectedPickup);
        } else {
          pendingPickupsFiltered.splice(
            pendingPickupsFiltered.indexOf(nearest) - 1,
            0,
            selectedPickup
          );
        }
      } else {
        pendingPickupsFiltered.splice(
          pendingPickupsFiltered.indexOf(nearest) + 1,
          0,
          selectedPickup
        );
      }
    }
  } else {
    console.log("no pickup found");
    // if not just push the postponed pickup and sort based on index
    pendingPickupsFiltered.push(selectedPickup); //After importing postponed we have to resort based on index
    pendingPickupsFiltered.sort((a, b) => a.index - b.index);
  }

  //If our remaining pickups are more than one then search for alt pickup, and if we find one that is not already in second spot, move it up.
  if (pendingPickupsFiltered.length > 1) {
    const closestPickup = getClosestPickup(curPos, pendingPickupsFiltered);
    if (closestPickup) {
      if (closestPickup.index !== pendingPickupsFiltered[1].index) {
        pendingPickupsFiltered.splice(1, 0, closestPickup);
      }
    }
  }

  return pendingPickupsFiltered;
};

const PickupStore = ({
  children,
  firebase,
  authUser,
  curPos,
  postponedPickups,
  postponedPickupsRemoveQueue,
  time,
}) => {
  const [pickupState, dispatchPickupState] = useReducer(
    pickupStoreReducer,
    initialState
  );
  const [extrasNotifications, setExtrasNotifications] = useState([]);
  const [removedNotifications, setRemovedNotifications] = useState([]);

  const extraPickupsRef = useRef();

  useEffect(() => {
    const fetchPickups = async () => {
      try {
        const pickupsDoc = await firebase
          .route(authUser.uid, pickupState.date)
          .get();

        const formatted = formatPickups(pickupsDoc, postponedPickups, curPos);
        console.log(formatted, "Formattted");

        const polylineCoords = getRouteCoords(formatted.rawPickupsNoDouble);

        dispatchPickupState({
          type: "FETCH_SUCCESS",
          payload: { ...formatted, polylineCoords },
        });
      } catch (error) {
        dispatchPickupState({ type: "FETCH_FAILURE", payload: error.message });
      }
    };

    fetchPickups();
  }, []);

  useEffect(() => {
    if (pickupState.pending) {
      dispatchPickupState({
        type: "SET_CLOSEST",
        payload: { curPos, pending: pickupState.pending },
      });
    }
  }, [curPos]);

  useEffect(() => {
    if (pickupState.pending) {
      if (postponedPickupsRemoveQueue.length !== 0) {
        if (postponedPickupsRemoveQueue[0].alternative) {
          delete postponedPickupsRemoveQueue[0].alternative;
        }
        dispatchPickupState({
          type: "BRING_BACK_PICKUP",
          payload: {
            selectedPickup: postponedPickupsRemoveQueue[0],
            curPos,
          },
        });
      }
    }
  }, [postponedPickupsRemoveQueue]);

  useEffect(() => {
    if (pickupState.pending) {
      const pending = pickupState.pending;

      const expiring = pending.filter((item) => {
        const timewindowUnspecified = convertTimeToSecs("23:59");
        const timewindowEnd = item.timewindow
          ? convertTimeToSecs(item.timewindow.split("-")[1])
          : timewindowUnspecified;

        return (
          !item.alternative &&
          timewindowEnd !== timewindowUnspecified &&
          timewindowEnd - time <= 60 * 30
        );
      });
      dispatchPickupState({
        type: "SET_EXPIRING",
        payload: expiring,
      });
    }
  }, [pickupState.pending, time]);

  // set up listeners for extra pickups
  useEffect(() => {
    if (!pickupState.isLoading) {
      if (pickupState.pending) {
        extraPickupsRef.current = 0;

        firebase.db
          .collection("amsterdam")
          .doc("routes")
          .collection(pickupState.date)
          .doc("pickups")
          .collection("extraPickups")
          .where("driverId", "==", authUser.uid)
          .onSnapshot((snapshot) => {
            snapshot.docChanges().forEach((change) => {
              switch (change.type) {
                case "added":
                  {
                    const pickup = change.doc.data();

                    // determine its index by adding it to the last index of existing pickups
                    pickup.index =
                      pickupState.raw.length + extraPickupsRef.current;
                    pickup.extraPickupId = change.doc.id;
                    extraPickupsRef.current++;
                    console.log(extraPickupsRef.current, "EXTRA PICKUPS ADDED");

                    if (!pickup.completed && !pickup.discarded) {
                      dispatchPickupState({
                        type: "ADD_EXTRA_PICKUP_PENDING",
                        payload: {
                          selectedPickup: pickup,
                          curPos,
                        },
                      });
                      // show notification to user by adding it to an extras notificatin array
                      setExtrasNotifications((prevNotifs) => [
                        ...prevNotifs,
                        pickup,
                      ]);
                    } else {
                      dispatchPickupState({
                        type: "ADD_EXTRA_PICKUP_PROCESSED",
                        payload: pickup,
                      });

                      // show notification to user by adding it to an extras notificatin array,
                      // even if it is completed by now
                      setExtrasNotifications((prevNotifs) => [
                        ...prevNotifs,
                        pickup,
                      ]);
                    }
                  }
                  break;
                case "removed":
                  {
                    // if removed from extra pickups collection then we simply remove it from pending or even processed
                    // use its extra pickup id to remove from state
                    const extraPickupId = change.doc.id;
                    dispatchPickupState({
                      type: "DELETE_EXTRA_PICKUP",
                      payload: { extraPickupId, curPos },
                    });
                    // show notification to user
                    const pickup = change.doc.data();
                    //delete extraPickup property so it shows up as a removed notification
                    delete pickup.extraPickup;
                    setRemovedNotifications((prevNotifs) => [
                      ...prevNotifs,
                      pickup,
                    ]);
                  }
                  break;
              }
            });
          });
      }
    }
  }, [pickupState.isLoading]);

  //setup listeners for removed pickups
  useEffect(() => {
    if (!pickupState.isLoading) {
      if (pickupState.pending) {
        firebase.db
          .collection("amsterdam")
          .doc("routes")
          .collection(pickupState.date)
          .doc("pickups")
          .collection("removedPickups")
          .where("driverId", "==", authUser.uid)
          .onSnapshot((snapshot) => {
            snapshot.docChanges().forEach((change) => {
              switch (change.type) {
                // if we add a removed pickup
                case "added":
                  {
                    const removedPickup = change.doc.data();
                    dispatchPickupState({
                      type: "DELETE_PICKUP",
                      payload: {
                        selectedPickup: removedPickup,
                        curPos,
                      },
                    });
                    // show notification to user
                    setRemovedNotifications((prevNotifs) => [
                      ...prevNotifs,
                      removedPickup,
                    ]);
                  }

                  break;
                // if we remove a removed pickup
                case "removed":
                  {
                    const removedPickup = change.doc.data();
                    dispatchPickupState({
                      type: "BRING_BACK_DELETED_PICKUP",
                      payload: {
                        selectedPickup: removedPickup,
                        curPos,
                      },
                    });
                    // show notification to user
                    //add extraPickup property so it shows up as an extra notifications
                    setExtrasNotifications((prevNotifs) => [
                      ...prevNotifs,
                      { ...removedPickup, extraPickup: true },
                    ]);
                  }
                  break;
              }
            });
          });
      }
    }
  }, [pickupState.isLoading]);

  console.log("PICKUP STORE COMPONENET RENDERING +++++++++++++++");

  return !pickupState.isLoading ? (
    <>
      <PickupStoreContext.Provider value={[pickupState, dispatchPickupState]}>
        {children}
      </PickupStoreContext.Provider>
      {(extrasNotifications.length || removedNotifications.length) && (
        <ExtrasNotifications
          extras={extrasNotifications}
          removed={removedNotifications}
          setExtras={setExtrasNotifications}
          setRemoved={setRemovedNotifications}
        />
      )}

      <ul className="pickup-notifier__list">
        {pickupState.expiring && pickupState.expiring.length > 0 && (
          <ExpiringNotifier
            pickupsNames={pickupState.expiring
              .map((pickup) => {
                const timewindowEnd = convertTimeToSecs(
                  pickup.timewindow.split("-")[1]
                );

                return `${pickup.address.name} (${Math.round(
                  (timewindowEnd - time) / 60
                )} mins)`;
              })
              .join(", ")}
            title={"Verloopt"}
            icon={"bell"}
            modifierClassname={"expiring"}
          />
        )}
        {postponedPickups.length > 0 && (
          <PostponedNotifier
            pickupsNames={postponedPickups
              .map((pickup) => pickup.address.name)
              .join(", ")}
            title={"Uitgesteld"}
            modifierClassname={"postponed"}
            icon={"clock"}
          />
        )}
        {pickupState.pending && (
          <ProgressNotifier
            pending={pickupState.pending}
            processed={pickupState.processed}
            expiring={pickupState.expiring}
            rawNoDoubles={pickupState.rawPickupsNoDouble}
            postponedPickups={postponedPickups}
            modifierClassname={"progress"}
            icon={"tasks"}
          />
        )}
      </ul>
    </>
  ) : (
    <Loader message={"Laden van ophaaladressen..."} />
  );
};

const formatPickups = (firestoreDoc, postponedPickups, curPos) => {
  const results = firestoreDoc.data();

  console.log(results, "RESULTSSSSSSSSSSSSSSSS");
  //if our resposne from firebase is undefined it means that the date or driveId don't match to a collection or document.
  //Then we set our pending state to undefined (to be differentiated from pending = null, null means we weren't abl eto receive a response from firebase).
  //If we set to undefined then we want to stop our loader and show a message that driver doesn;t have pickups for this date.
  if (results === undefined) {
    return {
      raw: undefined,
      pending: undefined,
      processed: undefined,
      rawPickupsNoDouble: undefined,
    };
  }

  const pickups = results.pickups;
  //Creating an array with the route objects we get back.
  const rawPickups = Object.keys(pickups).map((key) => {
    return {
      ...pickups[key],
      index: Number(key),
    };
  });

  const rawPickupsNoDouble = removeDuplicatesSafeOrder(rawPickups);


  console.log(rawPickupsNoDouble);

  const pendingPickups = rawPickupsNoDouble.filter((pickup) => {
    return !pickup.completed && !pickup.discarded;
  });

  //Have to check if there are any postponed pickups and not includ ethem if that's the case

  const pendingPickupsToPush = pendingPickups.filter((pickup) => {
    return !postponedPickups.find(
      (postPickup) => postPickup.index === pickup.index
    );
  });

  if (pendingPickupsToPush.length > 1) {
    const closestPickup = getClosestPickup(curPos, pendingPickupsToPush);
    if (closestPickup) {
      console.log(closestPickup, "closest");
      if (closestPickup.index !== pendingPickupsToPush[1].index) {
        pendingPickupsToPush.splice(1, 0, closestPickup);
      }
    }
  }

  console.log(rawPickupsNoDouble);

  const processedPickupsToPush = rawPickupsNoDouble.filter((pickup) => {
    return pickup.completed || pickup.discarded;
  });

  return {
    raw: rawPickups,
    rawPickupsNoDouble: rawPickupsNoDouble,
    pending: pendingPickupsToPush,
    processed: processedPickupsToPush,
  };
};

const getRouteCoords = (pickups) => {
  console.log("calculate polyline");
  if (!!pickups) {
    return pickups
      .filter((pickup) => pickup.id !== "break")
      .map((pickup) => {
        return {
          lat: pickup.address.lat,
          lng: pickup.address.lng,
        };
      });
  } else {
    return [];
  }
};

const withTimeCurPos = compose(withTimeStore, withCurrentPosition)(PickupStore);

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(withTimeCurPos);
