import React from 'react';

import LocationContext from './context';

//HOC that receives a condition function as a parameter and decides if it should redirect to a public route, or render the passed component.
const withCurrentPosition = (Component) => {
	class withCurrentPosition extends React.Component {
		render() {
			return (
				//Adding consumer component so that authUser value is available to us even before onAuthChange listener redirects.
				//As such we avoid showing the protected page before the redirect happens.
				<LocationContext.Consumer>
					{(curPos) => <Component curPos={curPos} {...this.props} />}
				</LocationContext.Consumer>
			);
		}
	}
	//Using recompose library to wrap 'withAuthorization' HOC with 'withRouter' and 'withFirebase' HOCs
	return withCurrentPosition;
};

export default withCurrentPosition;
