import React from "react";
// import sweetalert2 without inline styles
// this is to avoid having swal set inline styles and violate CSP
import Swal from "sweetalert2/dist/sweetalert2.js";
// import styles separately
import "sweetalert2/dist/sweetalert2.css";
import { withFirebase } from "../Firebase";

class SwalNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keepMounted: false,
    };
    this.Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
      didDestroy: (toast) => {
        toast.removeEventListener("mouseenter", Swal.stopTimer);
        toast.removeEventListener("mouseleave", Swal.resumeTimer);
      },
    });
  }
  componentDidMount() {
    console.log(this.props);
    this.chooseSwal(this.props);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // only if we get a different alert message then show new swal
    if (prevProps.message !== this.props.message) {
      this.setState({ keepMounted: true }, () => {
        this.chooseSwal(this.props);
        this.setState({ keepMounted: false });
      });
    }
  }

  chooseSwal = ({ message, error, alertStatus, closeAlert, logout }) => {
    console.log(this.el);
    switch (alertStatus) {
      case "Failure":
        Swal.fire({
          icon: "error",
          title: message,
          text: error,
          target: this.el,
          didDestroy: () => (this.state.keepMounted ? null : closeAlert()),
        });
        break;
      case "Success":
        Swal.fire({
          icon: "success",
          title: message,
          timer: 2000,
          showConfirmButton: false,
          target: this.el,
          didDestroy: () => (this.state.keepMounted ? null : closeAlert()),
        });
        break;
      case "Warning":
        Swal.fire({
          icon: "warning",
          title: message,
          text: error,
          confirmButtonText: "Nu inloggen",
          target: this.el,
          didDestroy: () => (this.state.keepMounted ? null : closeAlert()),

          didOpen: () => {
            this.props.firebase.auth.onAuthStateChanged((authUser) => {
              if (authUser === null) {
                Swal.clickCancel();
              }
            });
          },
        }).then((result) => {
          if (result.isConfirmed) {
            logout();
          }
        });
        break;

      case "Success--small":
        this.Toast.fire({
          icon: "success",
          title: message,
          target: this.el,
          didDestroy: () => (this.state.keepMounted ? null : closeAlert()),
        });

        break;
    }
  };

  render() {
    const { logoutNotification } = this.props;
    return (
      <div
        className={logoutNotification ? "logout-notification--small" : ""}
        ref={(el) => (this.el = el)}
      />
    );
  }
}

const SwalWithFirebase = withFirebase(SwalNotification);

export default SwalWithFirebase;
