import { findNearest } from "geolib";


export const removeDuplicatesSafeOrder = (arr) => {
  var seen = {};
  var retArr = [];
  for (var i = 0; i < arr.length; i++) {
    try{
      if (!(`${arr[i].address.street},${arr[i].address.name}` in seen)) {
        retArr.push(arr[i]);
        seen[`${arr[i].address.street},${arr[i].address.name}`] = arr[i].index;
      } else {
        const pickupToAddFieldTo = retArr.find(
          (pickup) => pickup.index === seen[`${arr[i].address.street},${arr[i].address.name}`]
        );

        pickupToAddFieldTo.associatedPickups = pickupToAddFieldTo.associatedPickups
          ? pickupToAddFieldTo.associatedPickups.concat([arr[i].index])
          : [arr[i].index];
      }
    }catch(error){}
  }

  return retArr;
};

export const getClosestPickup = (
  { latitude, longitude },
  pending,
  curPos,
  mode = "normal"
) => {
  const filtered = pending.filter(
    (pickup) =>
      pickup.id !== "end" && pickup.id !== "unload" && pickup.id !== "break"
  );
  const pickupCoords = filtered.map((pickup, index) => {
    return {
      latitude: pickup.address.lat,
      longitude: pickup.address.lng,
      index: pickup.index,
    };
  });

  //Remove first pickup as we don't want to include it in our check for alternative route
  //Both for coords and actual pending pickups array

  // if we use function for getting closest pickup to postponed, we dont want to remove first indexed pickup form the running
  mode !== "incoming"
    ? pickupCoords.shift()
    : pickupCoords.push({
        latitude: curPos.latitude,
        longitude: curPos.longitude,
        index: "CUR POS",
      }); // if we are handling incoming then we also add curPos into the mix
  const pendingWithoutFirst =
    mode !== "incoming" ? filtered.slice(1, pending.length) : filtered;

  if (pickupCoords.length >= 1) {
    const nearestPickup = findNearest({ latitude, longitude }, pickupCoords);
    console.log(pickupCoords, "COORDS")

    //if we find that the closest location is our current location then we send those coords back
    if (nearestPickup.index === "CUR POS") {
      console.log("NEAREST LOCATION IS CURRENT!!!!!!!!!!!!!!~~~~~~~~~~");
      return nearestPickup;
    } else {
      // else we match nearest pickup found to our pending pickups
      const closestPickup = pendingWithoutFirst.filter((pickup) => {
        return (
          pickup.address.lat === nearestPickup.latitude &&
          pickup.address.lng === nearestPickup.longitude
        );
      });

      const closestPickupCopy = closestPickup.slice();
      const pickupCopy = { ...closestPickupCopy[0] };
      const pickupCopyAddress = { ...closestPickupCopy[0].address };

      pickupCopyAddress.street = `${closestPickup[0].address.street}`;
      pickupCopy.address = pickupCopyAddress;
      pickupCopy.alternative = true;

      return pickupCopy;
    }
  } else {
    return null;
  }
};
