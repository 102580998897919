import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { compose } from "recompose";
//Importing components
import LoginPage from "../Login";
// import SignupPage from "../SignUp";
import AuthApp from "../AuthApp";
//Importing routes
import * as ROUTES from "../../constants/routes";
//Importing higher-order component 'withFirebase'
import { withAuthentication } from "../Session";

import withFontLoader from "../FontLoader";

// even thought list warns about an empty import, we need to import the file for font-awesome icons to load
import FontAwesome from "../FontLoader/font-awesome";

const Application = ({ softwareVersion }) => (
  <Router>
    {/* Route to component mapping: Each route inside router component renders a UI component according to the path the app points to. */}
    <Route exact path={ROUTES.LOGIN} component={LoginPage} />
    {/* <Route exact path={ROUTES.SIGN_UP} component={SignupPage} /> */}

    <AuthApp softwareVersion={softwareVersion} />
  </Router>
);

const App = compose(withFontLoader, withAuthentication)(Application);

export default App;
