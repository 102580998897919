import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

// setting db environment. Accordingly, firebase will be initiated in dev or prod mode.
const production = true;
//Firebase configuration
const firebaseConfig = production
  ? {
      // production environment
      apiKey: "AIzaSyBPiNmqbVmlQ5XwM5t4bwa_DjMr8iTrDRc",
      authDomain: "bedrijfsafval-development.firebaseapp.com",
      databaseURL: "https://bedrijfsafval-development.firebaseio.com",
      projectId: "bedrijfsafval-development",
      storageBucket: "bedrijfsafval-development.appspot.com",
      messagingSenderId: "839902693664",
      appId: "1:839902693664:web:b777b6a0d846ae69d62f45",
      measurementId: "${config.measurementId}"
    }
  : {
      // development environment
      apiKey: "AIzaSyDROycdcilKpKFwWq5O7ciAOgPnuqGzI4E",
      authDomain: "bedrijfsafvalamsterdam-c92b8.firebaseapp.com",
      databaseURL: "https://bedrijfsafvalamsterdam-c92b8-db3ec.europe-west1.firebasedatabase.app/",
      projectId: "bedrijfsafvalamsterdam-c92b8",
      storageBucket: "bedrijfsafvalamsterdam-c92b8.appspot.com",
      messagingSenderId: "804706597423",
      appId: "1:804706597423:web:9136d82ea78ff2874224da",
      measurementId: "${config.measurementId}",
    };

class Firebase {
  constructor() {
    //Initializing Firebase
    app.initializeApp(firebaseConfig);

    this.auth = app.auth();
    this.db = app.firestore();
    this.functions = app.functions();
    this.database = app.database();
    this.storage = app.storage();
    this.storageRef = this.storage.ref();

    this.firebaseTimestamp = app.database.ServerValue.TIMESTAMP;
    this.timeStamp = app.firestore.Timestamp;
    this.FieldValue = app.firestore.FieldValue;
    this.IN_PRODUCTION = production;
  }

  //Defining all AUTH API functions as methods to Firebase class.
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignout = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  //Defining all USER API functions as methods to Firebase class.

  //Get reference to single user
  user = (uid) => this.db.collection("users").doc(uid);

  //Get reference to single user's routes for a single date
  route = (uid, date) =>
    this.db
      .collection("amsterdam")
      .doc("routes")
      .collection(date)
      .doc("pickups")
      .collection("drivers")
      .doc(uid);

  //Add driver notifications
  addNotification = (doc) => this.db.collection("notifications").add(doc);

  info = () => this.db.collection("amsterdam").doc("generalInfo");

  summary = () => this.db.collection("amsterdam").doc("driverSummary");

  updateDistribution = (docId, changes) =>
    this.db.collection("distribution").doc(docId).update(changes);

  deleteDistrNotification = async (distrPickupId) => {
    const querySnap = await this.db
      .collection("notifications")
      .where("distrPickupId", "==", distrPickupId)
      .where("class", "!=", "distributie meldingen")
      .get();

    const deleteProms = querySnap.docs.map((doc) => doc.ref.delete());
    console.log(deleteProms, "DELETE PROMS");
    return Promise.all(deleteProms);
  };
}

export default Firebase;
