import React from "react";
import { compose } from "recompose";
import format from "date-fns/format";
import { Route } from "react-router-dom";
import { debounce } from "lodash";
import { buildPresence } from "./buildPresence";
//COMPONENTS
import Navigation from "../Navigation";
import PendingPage from "../Pending";
import CompletedPage from "../Completed";
import PickupStore from "../PickupStore";
import TimeStore from "../TimeStore";
import RoutePlanner from "../RoutePlanner";
// import MessagesPage from '../Messages';
import SettingsPage from "../Settings";
import GoogleMapsPage from "../GoogleMaps";
// import PasswordForgetPage from '../PasswordForget';
// import PasswordChangePage from '../PasswordChange';
import { FormPageStart, FormPageEnd } from "../FormPage";
//HOC
import { withGoogleWrapper } from "../GoogleMaps";
import { LocationStore } from "../Geolocation";
import { withAuthorization } from "../Session";

//Routes
import * as ROUTES from "../../constants/routes";
import Loader from "../Loader";

import SwalNotification from "../Swal";
import { convertTimeToSecs } from "../TimeStore/helpers";

// Seeds
import { anastasiaWrite } from "../Seeds/";

class AuthApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: format(new Date(), "y-MM-dd"),
      shiftStart: null,
      shiftTimerStarted: false,
      postponedPickups: [],
      postponedPickupsRemoveQueue: [],
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      showDriverForm: false, // turn to null to enable
      lastKey: null,
      startFormComplete: false,
      // alert
      showAlert: false,
      alertMessage: "",
      alertStatus: "",
      alertError: "",
      isNavVisible: true,
      driverDetails: null,
      generalInfo: null,
    };

    this.intervalId = null;
    this.timeouts = [];
  }

  // LIFECYCLE METHODS //
  componentDidMount() {
    // call buildPresence function which keeps track of the client's online,
    // offline status on realtime db and firestore
    buildPresence.call(this);
    //Start listener for window resize to check for landscape mode.
    window.addEventListener("resize", this.handleResize);
    // also get driver details to use when she sends notifications
    this.props.firebase
      .user(this.props.authUser.uid)
      .get()
      .then((doc) => {
        this.setState({
          driverDetails: { ...doc.data(), driverId: doc.id },
        });
      });

    // also get general info for notes/discarded msgs
    this.props.firebase
      .info()
      .get()
      .then((doc) => {
        this.setState({
          generalInfo: doc.data(),
        });
      });

    this.props.firebase
      .route(this.props.authUser.uid, this.state.date)
      .get()
      .then(this.setTimestampAndDriverForms)
      .catch((error) => {
        //Handle error fetching from Firestore.
        this.setState({
          showAlert: true,
          alertMessage: `Er is een probleem opgetreden bij het ophalen van uw pick-ups. Probeer de pagina te vernieuwen.`,
          alertError: `Error: ${error.message}`,
          alertStatus: "Failure",
        });
      });

    this.setState({
      showAlert: true,
      alertMessage: `Je bent succesvol ingelogd`,
      alertError: ``,
      alertStatus: "Success--small",
    });
  }

  componentWillUnmount() {
    //Clear interval
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
    if (this.timeouts.length > 0) {
      //Clear all timeouts
      this.timeouts.forEach((timeoutId) => clearTimeout(timeoutId));
      this.timeouts = [];
    }
    // unsubcribe from the event listener we set up in componentDidMount
    window.removeEventListener("resize", this.handleResize);
  }

  // HELPER METHODS //

  setTimestampAndDriverForms = (snapshot) => {
    const results = snapshot.data();
    //Only if results is defined(i.e. there are pickups for thsi date and driver) we want to search for timestamp.
    if (results) {
      //Get last key of pickups to be used when calling the end form.
      const pickups = results.pickups;
      const keys = Object.keys(pickups);
      const lastKey = keys[keys.length - 1];
      this.setState({ lastKey });

      // get timestamp if it exists and start timer
      if (pickups[0].time) {
        const timestamp = pickups[0].time.toDate();
        const timeInSecsWithinDay = convertTimeToSecs(
          `${timestamp.getHours()}:${timestamp.getMinutes()}`
        );

        this.setState({
          shiftStart: timeInSecsWithinDay,
          shiftTimerStarted: true,
        });
      }
      // get driver form for start location
      // if it doesn't exist show form

      // uncomment to enable driver forms

      //   if (pickups[0].driverForm === undefined) {
      //     this.setState({ showDriverForm: true });
      //   } else {
      //     // if driverForm is defined in firestore, then start form is completed
      //     // then we check if end location and end form are completed, in which case we show driverform and render end form
      //     if (pickups[lastKey].completed && !pickups[lastKey].driverForm) {
      //       this.setState({
      //         showDriverForm: true,
      //         startFormComplete: true,
      //       });
      //     } else {
      //       // if not completed, we hide form and set start form as completed
      //       this.setState({
      //         showDriverForm: false,
      //         startFormComplete: true,
      //       });
      //     }
      //   }
      // } else {
      //   this.setState({ showDriverForm: false }); //if we get no pickups back we just set the showDriverForm to false to be able to render pending component
    }
  };

  // function that fires when we complete start location
  startTimer = () => {
    if (!this.state.shiftTimerStarted) {
      const date = new Date();
      const timeLatest = convertTimeToSecs(
        `${date.getHours()}:${date.getMinutes()}`
      );
      // if timer is not already started
      this.setState({ shiftTimerStarted: true, shiftStart: timeLatest });
    }
  };

  postponePickup = (postponedPickup) => {
    //Setting one array with postponed to be read by componentdidmount in pending
    const postponedPickups = this.state.postponedPickups.slice();

    postponedPickups.push(postponedPickup);

    this.setState({ postponedPickups });
    //Setting one array to be read by componentdidupdate in pending when we want to reinstate our postponed pickup
    const timeoutId = setTimeout(() => {
      const postponedPickupToRemove = [];

      postponedPickupToRemove.push(postponedPickup);
      //removing it from list raed by componentdidmount
      const postponedPickups = this.state.postponedPickups.slice();
      const postponedFiltered = postponedPickups.filter(
        (pickup) => pickup.index !== postponedPickup.index
      );
      this.setState({
        postponedPickupsRemoveQueue: postponedPickupToRemove, // to be added back to pending
        postponedPickups: postponedFiltered,
      });
    }, 60000);

    this.timeouts.push(timeoutId);
  };

  handleResize = debounce((e) => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    this.setState({ windowWidth, windowHeight });
  }, 100);

  handleDriverForm = (e, index) => {
    e.preventDefault();
    const data = new FormData(e.target);
    //construct object we will pass to firestore
    const objData = { [`pickups.${index}.driverForm.completed`]: true };
    for (let name of data.keys()) {
      objData[`pickups.${index}.driverForm.${name}`] = data.get(name);
    }

    this.props.firebase
      .route(this.props.authUser.uid, this.state.date)
      .update({ ...objData })
      .then(() => {
        this.setState({
          // after we write data, then we hide form again
          // we set startForm to true in both cases, since it does not alter the end result
          showDriverForm: false,
          startFormComplete: true,
          showAlert: true,
          alertMessage: `${index === 0 ? "Start" : "Eind"} formulier ingediend`,
          alertError: ``,
          alertStatus: "Success--small",
        });
      })
      .catch((error) => {
        //Handle error fetching from Firestore.
        this.setState({
          showAlert: true,
          alertMessage: `Er is een probleem opgetreden bij het opslaan van uw gegevens. Vernieuw de pagina en probeer het opnieuw. `,
          alertError: `Error: ${error.message}`,
          alertStatus: "Failure",
        });
      });
  };

  // function that fires once we complete end location
  showFormEnd = () => {
    this.setState({ showDriverForm: false }); // turn to true to enable driver forms
  };

  // function that fires when client presses close button on alert
  closeAlert = () => {
    this.setState({ showAlert: false });
  };

  keepTrackOfNavVisibility = (isNavVisible) => {
    this.setState({ isNavVisible });
  };

  render() {
    const {
      showDriverForm,
      windowWidth,
      windowHeight,
      startFormComplete,
      lastKey,
      shiftTimerStarted,
      shiftStart,
      postponedPickupsRemoveQueue,
      postponedPickups,
      alertError,
      alertMessage,
      alertStatus,
      showAlert,
      isNavVisible,
      generalInfo,
      driverDetails,
    } = this.state;
    console.log("AUTH COMPONENET RENDERING +++++++++++++++");
    console.log(showDriverForm, "SHOW DDIRVE FORM");
    return (
      <LocationStore>
        <TimeStore>
          <PickupStore
            postponedPickups={postponedPickups}
            postponedPickupsRemoveQueue={postponedPickupsRemoveQueue}
          >
            <Navigation
              windowWidth={windowWidth}
              windowHeight={windowHeight}
              keepTrackOfNavVisibility={this.keepTrackOfNavVisibility}
              firebase={this.props.firebase}
            />
            {/* Main navigation routes */}
            {showAlert && (
              <SwalNotification
                message={alertMessage}
                alertStatus={alertStatus}
                error={alertError}
                closeAlert={this.closeAlert}
              />
            )}

            <Route
              path={ROUTES.PENDING}
              render={(props) =>
                showDriverForm !== null && !showDriverForm ? ( // if showDriverForm is 'filled' and set to false, show Pending component
                  <PendingPage
                    {...props}
                    shiftTimerStarted={shiftTimerStarted}
                    shiftStart={shiftStart}
                    startTimer={this.startTimer}
                    postponePickup={this.postponePickup}
                    postponedPickups={postponedPickups}
                    postponedPickupsRemoveQueue={postponedPickupsRemoveQueue}
                    windowWidth={windowWidth}
                    showFormEnd={this.showFormEnd}
                    isNavVisible={isNavVisible}
                    windowHeight={windowHeight}
                    driverDetails={driverDetails}
                    generalInfo={generalInfo}
                  />
                ) : showDriverForm ? ( // if the above conditions are not true, then set another ternary operator
                  //  if showForm is true, then check if start form is completed in the first leg of new ternary
                  !startFormComplete ? (
                    // if it is not, show it
                    <FormPageStart
                      {...props}
                      handleDriverForm={this.handleDriverForm}
                      index={0}
                    />
                  ) : (
                    // if it is, show end form
                    <FormPageEnd
                      {...props}
                      handleDriverForm={this.handleDriverForm}
                      index={lastKey}
                    />
                  )
                ) : (
                  // if showDriverForm is still null then show loader
                  <Loader
                    {...props}
                    message={"Controleren van ophaaladressen..."}
                  />
                )
              }
            />

            <Route
              path={ROUTES.COMPLETED}
              render={(props) => (
                <CompletedPage {...props} windowHeight={windowHeight} />
              )}
            />
            {/* <Route path={ROUTES.MESSAGES} component={MessagesPage} /> */}
            <Route
              path={ROUTES.SETTINGS}
              render={(props) => (
                <SettingsPage
                  {...props}
                  softwareVersion={this.props.softwareVersion}
                />
              )}
            />
            {/* Routes in pending */}
            <Route
              exact
              path={ROUTES.G_MAPS}
              render={(props) => (
                <GoogleMapsPage {...props} windowHeight={windowHeight} />
              )}
            />
            <Route
              exact
              path={ROUTES.ROUTE_PLANNER}
              render={(props) => (
                <RoutePlanner
                  {...props}
                  windowHeight={windowHeight}
                  firebase={this.props.firebase}
                />
              )}
            />
            {/* Routes in settings */}
            {/* <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
				<Route path={ROUTES.PASSWORD_CHANGE} component={PasswordChangePage} /> */}
          </PickupStore>
        </TimeStore>
      </LocationStore>
    );
  }
}

const AuthAppWithLocation = compose(withGoogleWrapper)(AuthApp);

const condition = (authUser) => !!authUser;

const AuthAppWithAuth = withAuthorization(condition)(AuthAppWithLocation);

export default AuthAppWithAuth;
