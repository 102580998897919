//Importing geolib library to calculate distances.
import { getDistance } from "geolib";

export const isWithinRadius = (selectedPickup, currentPosition) => {
  // if pickup is not start location and it is completed, check driver's location compared to pickup's location
  if (
    selectedPickup.address.name !== "Start Locatie" &&
    selectedPickup.address.name !== "Pauze"
  ) {
    const { latitude, longitude, accuracy } = currentPosition;
    console.log(accuracy, "ACCURRACY WITHIN RADIUS");

    // if gps data is inaccurate we return true and allow user to continue
    if (accuracy > 50) {
      return true;
    }

    const distanceFromTarget = getDistance(
      { latitude, longitude },
      {
        latitude: selectedPickup.address.lat,
        longitude: selectedPickup.address.lng,
      }
    );

    //if this corresponds to afvalboeien then we have annother allowed radius
    if (selectedPickup.address.garbageType == 'afvalboeien' && distanceFromTarget > 500) {
      console.log("not within RADIUS");

      return false;
    }

    // if distance from pickup location is larger than 200meters show alert and return from handleCompleteAndDiscard function
    if (selectedPickup.address.garbageType != 'afvalboeien' && distanceFromTarget > 200) {
      console.log("not within RADIUS");

      return false;
    }

    // if we get past check
    return true;
  }

  // if location is start or pause, we don't check for proximity
  return true;
};
