import React, { Component } from "react";


import { withFirebase } from "../Firebase";

class LogOutButton extends Component {
  onClick = () => {
    this.props.firebase.doSignout();

    // set our status in realtime firebase to offline as well
    const uid = this.props.firebase.auth.currentUser.uid;
    const userStatusDatabaseRef = this.props.firebase.database.ref(
      "/status/" + uid
    );
    const isOfflineForDatabase = {
      state: "offline",
      last_changed: this.props.firebase.firebaseTimestamp,
    };

    userStatusDatabaseRef.set(isOfflineForDatabase);
  };

  render() {
    return (
      <button className="btn--logout" onClick={this.onClick}>
        Uitloggen
      </button>
    );
  }
}

//SignOut button gets access to firebase instance by using the higher-order component 'withFirebase',
export default withFirebase(LogOutButton);
