import React from "react";

import PendingListItem from "./PendingListItem";
import ListNoResults from "../ListNoResults";

const PendingList = (props) => {
  const pendingPickups = props.pending;

  if (pendingPickups === undefined) {
    return (
      <ListNoResults
        message={"Er zijn geen locaties in de database voor deze datum. "}
      />
    );
  }

  if (pendingPickups.length === 0) {
    return <ListNoResults message={"Er zijn nog geen locaties voltooid."} />;
  }

  const pendingPickupsList = pendingPickups.map((pickup, index) => {
    //We are looping through our pending pickups. If pickup object is NOT completed and NOT discarded we add it to our list.
    //Each pickup is an object with a key starting at '1' and an object containing pickup details as a value.
    //As such, we start at index + 1, as we discarded the first one in componentDidMount.

    return (
      <PendingListItem
        key={`${pickup.address.street}-${pickup.address.name}${
          pickup.alternative ? "-alt" : ""
        }${pickup.extraPickup ? `-extra-${pickup.created.seconds}` : ""}`}
        value={pickup}
        initialPickups={props.initialPickups}
        index={pickup.index}
        actualIndex={index}
        handleDialogAssignment={props.handleDialogAssignment}
        currentPosition={props.currentPosition}
        shiftTimerStarted={props.shiftTimerStarted}
        shiftStart={props.shiftStart}
        handleCompleteAndDiscard={props.handleCompleteAndDiscard}
        windowWidth={props.windowWidth}
        handleGmapsLandMode={props.handleGmapsLandMode}
        driverDetails={props.driverDetails}
        generalInfo={props.generalInfo}
      />
    );
  });

  return (
    <ul className="pickup__list pickup__list--pending">{pendingPickupsList}</ul>
  );
};

export default PendingList;
