import React, { Component } from "react";
import ReactDOM from "react-dom";

import Alert from "../Alert";
import SwalNotification from "../Swal";

//Icons component.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ShowRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      modalMessage: "",
      showAlert: false,
      alertMessage: "",
      alertStatus: "",
      alertError: "",
    };
  }

  componentDidMount() {
    this.loadMap();

    this.sectionMaps.style.height = `${this.props.windowHeight}px`;
    //The loadMap() function is called after the component has been rendered, because we need a ref to the DOM node to render the map.
    //DOM nodes in React become availbale only after rendering components.
  }

  componentDidUpdate(prevProps, prevState) {
    //Handling the case when Google API is not available
    //If our prev props are not equal to the current props, it means that the google object we are receiving is changing.
    //So we call loadMap() again, which first check to see if we have an instance of google class, and then draws map.

    // always change the height of map, if window height changes
    if (prevProps.windowHeight !== this.props.windowHeight) {
      this.sectionMaps.style.height = `${this.props.windowHeight}px`;
    }

    // if (prevProps.currentLocation !== this.props.currentLocation) {
    //   // we set this so that in landscape mode , the map doesn't reload each time that our position changes
    //   return;
    // }

    // instead we only reload when our target changes
    if (
      prevProps.google !== this.props.google ||
      prevProps.targetLocation.lat !== this.props.targetLocation.lat ||
      prevProps.targetLocation.lng !== this.props.targetLocation.lng
    ) {
      this.loadMap();
    }
  }

  loadMap() {
    //Checks if google is available.
    if (this.props && this.props.google) {
      if (this.props.currentLocation) {
        const { google } = this.props;
        const maps = google.maps;

        const mapRef = this.refs.map;

        //Reference to the actual DOM element.
        const node = ReactDOM.findDOMNode(mapRef);

        //maps.Map() is the constructor that instantiates the map
        this.map = new maps.Map(node, {
          zoom: 14,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_TOP,
          },
          disableDefaultUI: true, //remove all controls
          zoomControl: true,
        });

        //Get reference to google methods used for showing route instructions.
        const directionsRenderer = new maps.DirectionsRenderer();
        const directionsService = new maps.DirectionsService();

        //Set loaded map as the one to be used to render instuctions.
        directionsRenderer.setMap(this.map);
        //Calls function that calculates and shows route intructions on current map.
        this.calculateAndDisplayRoute(directionsService, directionsRenderer);

        this.setState({
          showModal: true,
          modalMessage: "Openen in apple maps",
        });
      } else {
        this.setState({
          showModal: true,
          modalMessage:
            "Schakel locatietoegang in op uw apparaat en vernieuw de pagina.",
        });
      }
    } else {
      this.setState({
        showModal: true,
        modalMessage:
          "Google maps is niet beschikbaar. Vernieuw de pagina en probeer het opnieuw.",
      });
    }
  }

  calculateAndDisplayRoute(directionsService, directionsRenderer) {
    const { latitude, longitude } = this.props.currentLocation;

    const { lat, lng } = this.props.targetLocation;
    const { google } = this.props;

    directionsService.route(
      {
        origin: {
          lat: latitude,
          lng: longitude,
        },
        destination: {
          lat,
          lng,
        },
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (response, status) => {
        if (status === "OK") {
          directionsRenderer.setDirections(response);
        } else {
          this.setState({
            showAlert: true,
            alertStatus: "Failure",
            alertMessage: `Verzoek om routebeschrijving is mislukt. `,
            alertError: `Error: ${status}`,
          });
        }
      }
    );
  }

  closeAlert = () => {
    this.setState({ showAlert: false });
  };

  render() {
    const { latitude, longitude } = this.props.currentLocation;
    const { lat, lng } = this.props.targetLocation;
    const { landscapeMode, google, closeGmapsLandMode } = this.props;
    const {
      modalMessage,
      showAlert,
      alertError,
      alertMessage,
      alertStatus,
      showModal,
    } = this.state;

    return (
      <section
        className={`section-maps ${
          landscapeMode ? "section-maps--landscape-mode" : ""
        }`}
        ref={(node) => (this.sectionMaps = node)}
      >
        <div className="google-maps">
          {showAlert && (
            <SwalNotification
              message={alertMessage}
              alertStatus={alertStatus}
              error={alertError}
              closeAlert={this.closeAlert}
            />
          )}

          {landscapeMode && (
            <ModalCloseLandMode
              message={"Close"}
              closeGmapsLandMode={closeGmapsLandMode}
            />
          )}

          {showModal && (
            <Modal
              landscapeMode={landscapeMode}
              message={modalMessage}
              link={
                latitude && // current location lat
                lat && // target location lat
                google
                  ? `http://maps.apple.com/?saddr=${latitude},${longitude}&daddr=${lat},${lng}&dirflg=d`
                  : ""
              }
            />
          )}

          <div
            className={`google-maps__container ${
              landscapeMode ? "google-maps__container--landscape-mode" : ""
            }`}
            ref="map"
          ></div>
        </div>
      </section>
    );
  }
}

const Modal = (props) => {
  const content = (
    <div
      className={`google-maps__modal ${
        props.landscapeMode ? "google-maps__modal--landscape-mode" : ""
      }`}
    >
      <p className="google-maps__message">
        <FontAwesomeIcon className="google-maps__icon" icon="map-marker-alt" />
        {props.message}
        {props.link ? (
          <FontAwesomeIcon
            className="google-maps__icon google-maps__icon--external"
            icon="external-link-alt"
          />
        ) : (
          ""
        )}
      </p>
    </div>
  );

  if (props.link) {
    return (
      <a
        className="google-maps__link"
        href={props.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {content}{" "}
      </a>
    );
  } else {
    return content;
  }
};

const ModalCloseLandMode = (props) => (
  <div className="google-maps__modal google-maps__modal--close">
    <p className="google-maps__message google-maps__message--close">
      {/* {props.message} */}
      <span
        className="google-maps__icon-container"
        onClick={props.closeGmapsLandMode}
      >
        <FontAwesomeIcon
          className="google-maps__icon google-maps__icon--close"
          icon="times"
        />
      </span>
    </p>
  </div>
);

export default ShowRoute;
