import React from "react";

import AuthUserContext from "./context";
import { withFirebase } from "../Firebase";

import Loader from "../Loader";
import Alert from "../Alert";
import SwalNotification from "../Swal";

//Creating higher-order component to be used to pass the auth value into a component.
//It will also handle the session for the authenticated user to keep App component clean.
const withAuthentication = (Component) => {
  class withAuthentication extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        authUser: null,
        showAlert: false,
        alertMessage: "",
        alertStatus: "",
        alertError: "",
        showLogoutInAlert: false,
        logoutNotification: false,
        alreadyLoggedIn: false,
      };

      this.sessionTimeout = null;
      this.warningTimeout = null;
    }

    componentDidMount() {
      console.log(this.state.alreadyLoggedIn);
      this.unsubscribe = this.props.firebase.auth.onAuthStateChanged(
        (authUser) => {
          if (authUser === null) {
            this.state.showAlert &&
              this.setState({ showAlert: false, showLogoutInAlert: false });
            this.sessionTimeout && clearTimeout(this.sessionTimeout);
            this.warningTimeout && clearTimeout(this.warningTimeout);
            this.sessionTimeout = null;
            this.warningTimeout = null;
            this.setState({
              authUser: undefined,
              showAlert: this.state.alreadyLoggedIn ? true : false,
              alertStatus: "Success--small",
              alertMessage: `Succesvol uitgelogd.`,
              alertError: ``,
              showLogoutInAlert: false,
              logoutNotification: true,
            });
          } else {
            authUser.getIdTokenResult().then((idTokenResult) => {
              this.setState({ idToken: idTokenResult });

              const authTime = idTokenResult.claims.auth_time * 1000;
              const sessionDuration = 1000 * 60 * 60 * 8; // millisecs/secs/mins/hours
              const warningTime = 1000 * 60 * 5; // millisecs/ secs/ mins
              const millisecondsUntilExpiration =
                sessionDuration - (Date.now() - authTime);
              const millisecondsUntilWarning =
                millisecondsUntilExpiration - warningTime; // set warning at 5 mins before exp
              //show warning only when there is more than 5 mins remaining when user signs in

              if (millisecondsUntilWarning > 0) {
                this.warningTimeout = setTimeout(
                  () =>
                    this.setState({
                      showAlert: true,
                      alertStatus: "Warning",
                      alertMessage: `U wordt uitgelogd in ${Math.round(
                        (millisecondsUntilExpiration -
                          millisecondsUntilWarning) /
                          1000 /
                          60
                      )} minute(s).`,
                      alertError: "Log opnieuw in om uw sessie te verlengen. ",
                      showLogoutInAlert: true,
                      logoutNotification: false,
                    }),
                  millisecondsUntilWarning
                );
              }
              // if session expiration is less than 5 mins show custom alert
              else if (millisecondsUntilExpiration > 0) {
                this.setState({
                  showAlert: true,
                  alertStatus: "Warning",
                  alertMessage: `U wordt uitgelogd in ${Math.round(
                    millisecondsUntilExpiration / 1000 / 60
                  )} minute(s).`,
                  alertError: "Log opnieuw in om uw sessie te verlengen. ",
                  showLogoutInAlert: true,
                });
              } else if (millisecondsUntilExpiration <= 0) {
                //if session has already expired simply log out user
                this.props.firebase.doSignout();
                this.setState({ authUser: undefined });
                return; //and return to avoid setting state with authuser
              }

              if (millisecondsUntilExpiration > 0) {
                // if session is more than 0 secs to expiration, set timeout for log out
                this.sessionTimeout = setTimeout(
                  () => this.props.firebase.doSignout(),
                  millisecondsUntilExpiration
                );
              }
            });

            // after we are done with all checks then we set already logged in to true,
            // so we can check when logging out if we are to show the logout out notification
            this.setState({ authUser, alreadyLoggedIn: true });
          }
        },
        (error) =>
          this.setState({
            showAlert: true,
            alertStatus: "Failure",
            alertMessage: `Kon uw inloggegevens niet verifiëren. Vernieuw de pagina en probeer het opnieuw. `,
            alertError: `Error: ${error.message}`,
            showLogoutInAlert: false,
            logoutNotification: false,
          })
      );
    }
    componentWillUnmount() {
      //onAuthStateChanged function returns a function to us that unsubscribes the function we originally gave it.
      this.unsubscribe();
    }

    closeAlert = () => {
      this.setState({ showAlert: false });
    };

    logoutInAlert = () => {
      this.props.firebase.doSignout();
    };

    render() {
      const {
        showAlert,
        alertError,
        alertMessage,
        alertStatus,
        showLogoutInAlert,
        logoutNotification,
      } = this.state;
      console.log("Swal in Authentication", showAlert);
      //If we get authUser back from firebase and its valid or it empty (in this case we set it to undefined), then we show our login page.
      //Then the login page, if it sees that there is an authuser automatically redirects to dashboard. If not asks for credentials.
      //While the authUser is not received form firebase yet and is therefore null, we show our loader.
      return (
        <>
          {showAlert && (
            <SwalNotification
              message={alertMessage}
              alertStatus={alertStatus}
              error={alertError}
              closeAlert={this.closeAlert}
              logout={showLogoutInAlert ? this.logoutInAlert : null}
              logoutNotification={logoutNotification}
            />
          )}
          {this.state.authUser || this.state.authUser === undefined ? (
            <AuthUserContext.Provider value={this.state.authUser}>
              <Component {...this.props} authUser={this.state.authUser} />
            </AuthUserContext.Provider>
          ) : (
            <Loader message="Controleren van inloggegevens..." />
          )}
        </>
      );
    }
  }
  //Wrapping HOC 'withAuthUser' with HOC 'withFirebase' so that an instance of Firebase becomes available in it.
  return withFirebase(withAuthentication);
};

export default withAuthentication;
