import React from "react";

import GoogleAPIContext from "./context";

const withGoogleAPI = (Component) => (props) => (
  <GoogleAPIContext.Consumer>
    {(google) => <Component google={google} {...props} />}
  </GoogleAPIContext.Consumer>
);

export { withGoogleAPI };
