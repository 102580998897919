//Setting variables for app routes

//Landing pages
export const LOGIN = "/";
export const PASSWORD_FORGET = "/pw-forget";

//Main pages
export const PENDING = "/pending";
export const COMPLETED = "/completed";
export const MESSAGES = "/messages";
export const SETTINGS = "/settings";
export const ROUTE_PLANNER = "/route-planner";

//Settings pages
export const PASSWORD_CHANGE = "/pw-change";

//Other  pages
export const G_MAPS = "/g-maps/:coords";
export const FORM = "/form/:stage";
export const SIGN_UP = "/signup";
